import { red } from '@mui/material/colors'
import { components } from './components'

const themeOptions = {
    typography: {
        fontSize: 16,
        H1: {
            fontSize: '3rem',
            fontWeight: 700,
            lineHeight: 1.2,
        },
        H2: {
            fontSize: '2.44rem',
            fontWeight: 700,
            lineHeight: 1.2,
        },
        H3: {
            fontSize: '1.95rem',
            fontWeight: 700,
            lineHeight: 1.2,
        },
        H4: {
            fontSize: '1.5rem',
            fontWeight: 500,
            lineHeight: 1.2,
        },
        L1: {
            fontSize: '3rem',
            fontWeight: 400,
            lineHeight: 1.2,
        },
        L2: {
            fontSize: '2.44rem',
            fontWeight: 400,
            lineHeight: 1.2,
        },
        L3: {
            fontSize: '1.95rem',
            fontWeight: 400,
            lineHeight: 1.2,
        },
        L4: {
            fontSize: '1.5rem',
            fontWeight: 400,
            lineHeight: 1.2,
        },
        L: {
            fontSize: '1.25rem',
            fontWeight: 400,
        },
        M: {
            fontSize: '1rem',
            fontWeight: 400,
        },
        S: {
            fontSize: '0.8125rem',
            fontWeight: 400,
        },
        fontFamily: [
            'Poppins',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },

    status: {
        danger: red[500],
    },
    components: {
        ...components,
    },
}

export default themeOptions
