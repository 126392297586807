import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationEN from 'app/locales/en/translation.json'
import translationAR from 'app/locales/ar/translation.json'
import translationFR from 'app/locales/fr/translation.json'

const availableLanguages = ['en', 'fr', 'ar'];

const checkLanguage = (lang) => {
    let language = lang;
    if (lang.includes("-")) {
        const langSplit = lang.split("-");
        language = langSplit[0];
    }
    return availableLanguages.includes(language) ? language : 'en';
}

const resources = {
    en: {
        translation: translationEN,
    },
    ar: {
        translation: translationAR,
    },
    fr: {
        translation: translationFR,
    },
}

i18n.use(initReactI18next).init({
    resources,
    lng: checkLanguage(navigator.language) || 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
        escapeValue: false, // react already safes from xss
    },
})

export default i18n
