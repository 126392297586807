import {
    GET_TEAM_MEMBERS,
} from '../actions/TeamsActions'

const initialState = {
    teamMembers: [],
    meta: {},
}

const TeamsReducer = function (state = initialState, action) {
    switch (action.type) {
    case GET_TEAM_MEMBERS: {
        return {
            ...state,
            ...action.payload,
        }
    }
    default: {
        return {
            ...state,
        }
    }
    }
}

export default TeamsReducer
