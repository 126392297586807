import {
    UPLOAD_IAMGE,
} from '../actions/ImageUploadActions'

const initialState = {
    imagePath: null,
    imageType: null,
}

const ImageUploadReducer = function (state = initialState, action) {
    switch (action.type) {
    case UPLOAD_IAMGE: {
        return {
            ...state,
            ...action.payload,
        }
    }
    default: {
        return {
            ...state,
        }
    }
    }
}

export default ImageUploadReducer
