import { differenceInSeconds } from 'date-fns'
import { default as passwordComplexity } from 'joi-password-complexity';
import { get } from 'lodash'
import CountryList from 'app/data/country.json'
import { authRoles } from "app/auth/authRoles";
import { tempFeatureAllowedEmailDomains } from 'app/utils/constant';

export const convertHexToRGB = (hex) => {
    // check if it's a rgba
    if (hex.match('rgba')) {
        let triplet = hex.slice(5).split(',').slice(0, -1).join(',')
        return triplet
    }

    let c
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('')
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = '0x' + c.join('')

        return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')
    }
}

export function debounce(func, wait, immediate) {
    var timeout
    return function () {
        var context = this,
            args = arguments
        clearTimeout(timeout)
        timeout = setTimeout(function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        }, wait)
        if (immediate && !timeout) func.apply(context, args)
    }
}

export function isMobile() {
    if (window) {
        return window.matchMedia(`(max-width: 767px)`).matches
    }
    return false
}

export function isMdScreen() {
    if (window) {
        return window.matchMedia(`(max-width: 1199px)`).matches
    }
    return false
}

function currentYPosition(elm) {
    if (!window && !elm) {
        return
    }
    if (elm) return elm.scrollTop
    // Firefox, Chrome, Opera, Safari
    if (window.pageYOffset) return window.pageYOffset
    // Internet Explorer 6 - standards mode
    if (document.documentElement && document.documentElement.scrollTop)
        return document.documentElement.scrollTop
    // Internet Explorer 6, 7 and 8
    if (document.body.scrollTop) return document.body.scrollTop
    return 0
}

function elmYPosition(elm) {
    var y = elm.offsetTop
    var node = elm
    while (node.offsetParent && node.offsetParent !== document.body) {
        node = node.offsetParent
        y += node.offsetTop
    }
    return y
}

export function scrollTo(scrollableElement, elmID) {
    var elm = document.getElementById(elmID)

    if (!elmID || !elm) {
        return
    }

    var startY = currentYPosition(scrollableElement)
    var stopY = elmYPosition(elm)

    var distance = stopY > startY ? stopY - startY : startY - stopY
    if (distance < 100) {
        scrollTo(0, stopY)
        return
    }
    var speed = Math.round(distance / 50)
    if (speed >= 20) speed = 20
    var step = Math.round(distance / 25)
    var leapY = stopY > startY ? startY + step : startY - step
    var timer = 0
    if (stopY > startY) {
        for (var i = startY; i < stopY; i += step) {
            setTimeout(
                (function (leapY) {
                    return () => {
                        scrollableElement.scrollTo(0, leapY)
                    }
                })(leapY),
                timer * speed,
            )
            leapY += step
            if (leapY > stopY) leapY = stopY
            timer++
        }
        return
    }
    for (let i = startY; i > stopY; i -= step) {
        setTimeout(
            (function (leapY) {
                return () => {
                    scrollableElement.scrollTo(0, leapY)
                }
            })(leapY),
            timer * speed,
        )
        leapY -= step
        if (leapY < stopY) leapY = stopY
        timer++
    }
    return false
}

export function getTimeDifference(date) {
    let difference = differenceInSeconds(new Date(), date)

    if (difference < 60) return `${Math.floor(difference)} sec`
    else if (difference < 3600) return `${Math.floor(difference / 60)} min`
    else if (difference < 86400) return `${Math.floor(difference / 3660)} h`
    else if (difference < 86400 * 30)
        return `${Math.floor(difference / 86400)} d`
    else if (difference < 86400 * 30 * 12)
        return `${Math.floor(difference / 86400 / 30)} mon`
    else return `${(difference / 86400 / 30 / 12).toFixed(1)} y`
}

export function generateRandomId() {
    let tempId = Math.random().toString()
    let uid = tempId.substr(2, tempId.length - 1)
    return uid
}

export function getQueryParam(prop) {
    var params = {}
    var search = decodeURIComponent(
        window.location.href.slice(window.location.href.indexOf('?') + 1),
    )
    var definitions = search.split('&')
    definitions.forEach(function (val) {
        var parts = val.split('=', 2)
        params[parts[0]] = parts[1]
    })
    return prop && prop in params ? params[prop] : params
}

export function classList(classes) {
    return Object.entries(classes)
        .filter((entry) => entry[1])
        .map((entry) => entry[0])
        .join(' ')
}

export const flat = (array) => {
    var result = []
    array.forEach(function (a) {
        result.push(a)
        if (Array.isArray(a.children)) {
            result = result.concat(flat(a.children))
        }
    })
    return result
}

export const isValidPassword = (password) => {
    const complexityOptions = {
        min: 8,
        max: 25,
        lowerCase: 1,
        upperCase: 1,
        numeric: 1,
        symbol: 1,
        requirementCount: 4,
    };
    const passwordValidStatus = passwordComplexity(complexityOptions, 'Password').validate(password);
    const hasError = get(passwordValidStatus, 'error');

    return !hasError;
}

export const getCurrentTime = () => {
    const date = new Date();
    return date.toISOString();
}

export const getCountryList = () => {
    return CountryList;
}

export const getCountryByName = (countyName) => {
    const country = CountryList.filter(country => country.name === countyName);
    return country[0];
}

export const getCountryByCode = (countyCode) => {
    const country = CountryList.filter(country => country.code === countyCode);
    return country[0];
}

export const getUserRoles = () => {
    const roles = JSON.parse(localStorage.getItem('roles'));
    return roles || [];
}

export const isSuperAdmin = () => {
    const roles = getUserRoles();
    return roles.includes(authRoles.superAdmin);
}

export const isSiteAdmin = () => {
    const roles = getUserRoles();
    return roles.includes(authRoles.siteAdmin);
}

export const isAdmin = () => {
    const roles = getUserRoles();
    return roles.includes(authRoles.superAdmin)
        || roles.includes(authRoles.siteAdmin)
        || roles.includes(authRoles.smeAdmin);
}

export const isApprover = () => {
    const roles = getUserRoles();
    return roles.includes(authRoles.approver);
}

export const getAllowedRoutes = (navigations) => {
    const roles = getUserRoles();
    return navigations.filter(nav => isAdmin() || roles.includes(nav.auth));
}

export const getDisplayAmount = (amount) => {
    return (amount / 100).toFixed(2);
}

export const getSmeId = () => {
    return localStorage.getItem('smeId') || null;
}

export const convertPxToRem = (px) => {
    return `${px / 16}rem`;
}

export const getUpdatedPath = (currentPath, targetPath) => {
    const currentParams = currentPath.split('/').filter((param) => param !== '');
    const targetParams = targetPath.split('/').filter((param) => param !== '');
    let updatedPath = targetPath;

    targetParams.forEach((targetParam, index) => {
        const isParam = targetParam.startsWith(':');
        if (isParam && currentParams.length > index) {
            updatedPath = updatedPath.replace(targetParam, currentParams[index]);
        }
    });

    return updatedPath;
}

export const getTagsSortedByIsDefault = (tags = []) => {
    let sortedTags = tags;
    sortedTags.sort((a, b) => (a.isDefault === b.isDefault ? 0 : a.isDefault ? -1 : 1));

    return sortedTags;
}

export const isFeatureAllowedUser = (user) => {
    const email = user?.email;
    if (!email) return false;

    const emailDomain = email.split('@')[1];

    return tempFeatureAllowedEmailDomains.includes(emailDomain)
}

export const formatText = (str, splitStr = "_") => {
    // Split the string into words
    const words = str.split(splitStr);

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Join the words back together with a space
    const formattedString = capitalizedWords.join(' ');

    return formattedString;
}

const getAllowedRoutesWithFeatures = (navigations, featruesList) => {
    const roles = getUserRoles();

    return navigations.filter(nav => {
        const path = nav.path.replace('/', '').replace('-', '_');

        if (typeof featruesList[path] === 'undefined') {
            return isAdmin() || nav.auth.toString() === authRoles.all || roles.includes(nav.auth);
        } else {
            return (featruesList[path].is_enabled || featruesList[path].show_advert) && (isAdmin() || nav.auth.toString() === authRoles.all || roles.includes(nav.auth));
        }
    });
}

const checkPageAuthorization = (user, feature) => {
    // const availableFeature = user.available_features[feature];
    const { is_enabled: isEnabled, show_advert: showAdvert } = user.available_features[feature];

    return (!isEnabled && !showAdvert) || false;
};

const doShowAdvert = (user, feature) => {
    // const { available_features: { funding: { is_enabled: isEnabled, show_advert: showAdvert } } } = user;
    const { is_enabled: isEnabled, show_advert: showAdvert } = user.available_features[feature];

    return (!isEnabled && showAdvert) || false;
};

export {
    getAllowedRoutesWithFeatures,
    checkPageAuthorization,
    doShowAdvert,
};
