import axios from 'axios.js'
import { get } from 'lodash'

export const GET_HR_PLATFORM_LIST = "GET_HR_PLATFORM_LIST";
export const NOTIFY_STATUS = "NOTIFY_STATUS"

export const getHRPlatformList = () => {
    return (dispatch) => {
        axios.get('/common/hr-platforms').then(response => {
            dispatch({
                type: GET_HR_PLATFORM_LIST,
                payload: {
                    hrList: get(response, "data.data", []),
                },
            })
        })
    }
}
