import axios from 'axios.js'
import { get } from 'lodash'
import { setLoading } from "app/redux/actions/LoadingActions";
import { setMessage } from "app/redux/actions/MessageActions";

export const GET_COMPANIES_BY_SITE_ID = "GET_COMPANIES_BY_SITE_ID";
export const GET_COMPANY_DETAILS_BY_ID = "GET_COMPANY_DETAILS_BY_ID";
export const GET_REPORT_DATA_BY_SME_ID = "GET_REPORT_DATA_BY_SME_ID";
export const CLEAR_REPORT_DATA = "CLEAR_REPORT_DATA";
export const REQUEST_PERMISION_FOR_COMPANY = "REQUEST_PERMISION_FOR_COMPANY";

export const getCompaniesBySiteId = (siteId) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.get(`site/companies/${siteId}`);

            dispatch({
                type: GET_COMPANIES_BY_SITE_ID,
                payload: {
                    status: null,
                    companyList: get(response, "data.data", []),
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export const getCompanyDetailsById = (smeId) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.get(`site/company/${smeId}`);

            dispatch({
                type: GET_COMPANY_DETAILS_BY_ID,
                payload: {
                    companyDetails: get(response, "data.data", []),
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export const getReportDataBySmeId = (smeId, startDate, endDate) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.get(`railz/report-data?sme_id=${smeId}&start_date=${startDate}&end_date=${endDate}`);

            dispatch({
                type: GET_REPORT_DATA_BY_SME_ID,
                payload: {
                    smeReportsDataAccounting: get(response, "data.data.accounting", []),
                    smeReportsDataAnalytics: get(response, "data.data.analytics", []),
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export const clearReportData = () => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            dispatch({
                type: CLEAR_REPORT_DATA,
                payload: {
                    smeReportsDataAccounting: [],
                    smeReportsDataAnalytics: [],
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export const requestPermisionForCompany = (data) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.post('funder/request-permission', data);

            dispatch({
                type: REQUEST_PERMISION_FOR_COMPANY,
                payload: {
                    status: get(response, "data.data.status", []),
                },
            })
            dispatch(setMessage({ message: response?.data?.message, variant: "success" }));
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}
