import React from 'react'
import { Box, styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import CustomButton from "app/components/button/CustomButton";

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}))

const JustifyBox = styled(FlexBox)(() => ({
    maxWidth: 320,
    flexDirection: 'column',
    justifyContent: 'center',
}))

const IMG = styled('img')(() => ({
    width: '100%',
    marginBottom: '32px',
}))

const NotFoundRoot = styled(FlexBox)(() => ({
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh !important',
}))

const NotFound = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()

    return (
        <NotFoundRoot>
            <JustifyBox>
                <IMG src="/assets/images/illustrations/404.svg" alt="" />
                <CustomButton
                    onClick={() => navigate('/')}
                    label={t('BackToDashboard')}
                    id={"btn-back-to-dashboard"}
                />
            </JustifyBox>
        </NotFoundRoot>
    )
}

export default NotFound
