import { styled, Box } from '@mui/material'
import React from 'react'
import Button from 'app/components/button/Button'
import { Text } from 'app/components/Typography'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom'

const Container = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.black.darker16}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: 'center',
    'img': {
        width: '100%',
        height: 'auto',
        maxWidth: '200px',
        padding: 20,
    },
}))

export const NoProductRequests = ({ member }) => {
    const { t } = useTranslation();
    const { memberId } = useParams();
    const navigate = useNavigate()

    return <Container>
        <img src="/assets/images/illustrations/request-products.svg" alt="no-products-requests" />
        <Text fontWeight={600} marginBottom={3}>
            {member.firstName} {t('DoesntHaveAnyProductRequests')}
        </Text>
        <Button variant="contained"
            onClick={() => navigate(`/team-and-approvals/member/${memberId}/request-products`, { state: member })}
        >Request products for {member.firstName}</Button>
    </Container >
}

