/* eslint-disable no-unused-vars */
import Mock from '../mock'

export const myProducts = [
    
]

const userProducts = [
    {
        "id": "P123",
        "name": "LG 27MP400 Full HD 27” IPS LED Monitor - Black",
        "image": "/assets/images/dell.png",
        "monthlyRental": 1500,
        "rentalLength": 36,
        "dateRequested": 1654855410000,
        "userId": '01G8ZD3PG3SDJR0C7CNPC0E5F9',
        "status": "PENDING",
    },
    {
        "id": "P124",
        "name": "Apple MacBook Air - 256 GB SSD, Space Grey",
        "image": "/assets/images/macbook.jpg",
        "monthlyRental": 1500,
        "rentalLength": 36,
        "dateRequested": 1654855410000,
        "userId": '01G8ZD3PG3SDJR0C7CNPC0E5F9',
        "status": "PENDING",
    },
]

const availableProducts = [
    {
        "id": "P123",
        "name": "LG 27MP400 Full HD 27” IPS LED Monitor - Black",
        "image": "/assets/images/dell.png",
        "monthlyRental": 1500,
        "rentalLength": 36,
        "category": "Monitors",
        "tags": ["Everyone"],
    },
    {
        "id": "P126",
        "name": "HP 22f 22-inch 21.5-inch LED Full HD IPS Monitor",
        "image": "/assets/images/hp-monitor.jpg",
        "monthlyRental": 1000,
        "rentalLength": 24,
        "category": "Monitors",
        "tags": ["Everyone"],
    },
    {
        "id": "P124",
        "name": "Apple MacBook Air - 256 GB SSD, Space Grey",
        "image": "/assets/images/macbook.jpg",
        "monthlyRental": 1500,
        "rentalLength": 36,
        "category": "Laptops",
        "tags": ["Everyone", "Designer"],
    },
    {
        "id": "P127",
        "name": "Dell XPS 15 laptop 15.6', 4K UHD InfinityEdge Touch",
        "image": "/assets/images/dell-laptop.jpeg",
        "monthlyRental": 1500,
        "rentalLength": 36,
        "category": "Laptops",
        "tags": ["Everyone"],
    },
    {
        "id": "P125",
        "name": "Apple iPhone 11 Pro 64GB 5.8 (2020)",
        "image": "/assets/images/iphone.png",
        "monthlyRental": 1500,
        "rentalLength": 36,
        "category": "Phones",
        "tags": ["Everyone"],
    },
    {
        "id": "P128",
        "name": "Google Pixel 6 Pro - 12GB | 128GB",
        "image": "/assets/images/pixel6.jpeg",
        "monthlyRental": 1500,
        "rentalLength": 36,
        "category": "Phones",
        "tags": ["Everyone"],
    },
]


Mock.onGet("/api/products").reply(function () {
    return new Promise(function (resolve) {
        setTimeout(function () {
            resolve( [200, availableProducts])
        }, 500);
    });
});

Mock.onGet('/api/user-products').reply(() => {
    return [200, userProducts]
})

Mock.onGet("/api/my-products").reply(function () {
    return new Promise(function (resolve) {
        setTimeout(function () {
            resolve( [200, myProducts])
        }, 500);
    });
});

Mock.onPost('/api/submit-product-requests').reply(config => {
    return new Promise(function(resolve) {
        setTimeout(function() {
            const data = JSON.parse(config.data);
            const requestedProductsWithQuantity = data.requestedProducts
            const user = data.user
            
            const date = new Date().getTime();

            const extractedProducts = [];
            requestedProductsWithQuantity.forEach(product => {
                extractedProducts.push(...[...Array(product.quantity)].map(() => product))
            })

            const iteratedRequestProducts = extractedProducts.map(product => {
                const {quantity: _, ...otherFields} = product
                const requestProduct = {
                    ...otherFields,
                    dateRequested: date,
                    status: 'PENDING',
                    userId: user.id,
                }
                return requestProduct
            })

            myProducts.push(...iteratedRequestProducts)
            
            resolve([200, { message: 'OK', result: myProducts }]);
        }, 1000);
    });
});