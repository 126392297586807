import { Container } from '@mui/material'
import Button from 'app/components/button/Button'
import MainHeader from 'app/components/MainHeader/MainHeader'
import { Heading } from 'app/components/Typography'
import React from 'react'
import styled from '@emotion/styled';
import TagsAndBudgetPage from './TagsAndBudgetPage'
import { TagsDialog } from 'app/components/TagsAndBudget/TagsDialog'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import useAuth from 'app/hooks/useAuth';

const PageContainer = styled(Container)(() => ({
    maxWidth: "1440px !important",
}))

const TagsAndBudget = () => {
    const { t } = useTranslation();
    const [opened, setOpened] = useState(false)

    const { user } = useAuth()

    if (!user) return null

    const handleCreateTagClose = () => {
        setOpened(false)
    }

    return <>
        <MainHeader >
            <MainHeader.LeftContent>
                <Heading>
                    {t('Tags')}
                </Heading>
            </MainHeader.LeftContent>
            <MainHeader.RightContent>
                <Button variant="contained" color="primary" sx={{ width: { xs: '100%', md: 'auto' } }} onClick={() => setOpened(!opened)}>{t('CreateNewTag')}</Button>
            </MainHeader.RightContent>
        </MainHeader>
        <PageContainer>
            <TagsAndBudgetPage />
        </PageContainer>
        <TagsDialog opened={opened} mode="CREATE" handleTagClose={handleCreateTagClose} />
    </>
}

export default TagsAndBudget