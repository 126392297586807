import {
    GET_AI_ANSWER,
    GET_AI_RESOURCES,
    GET_LABEL_SETS,
    CLEAR_AI_RESULTS,
} from '../actions/FindInformationAction'

const initialState = {
    chat: {},
    resources: [],
    labelsets: [],
}

const FindInformationReducer = function (state = initialState, action) {
    switch (action.type) {
    case GET_AI_ANSWER: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case GET_AI_RESOURCES: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case GET_LABEL_SETS: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case CLEAR_AI_RESULTS: {
        return {
            ...state,
            ...action.payload,
        }
    }
    default: {
        return {
            ...state,
        }
    }
    }
}

export default FindInformationReducer
