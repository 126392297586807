import {
    GET_HR_PLATFORM_LIST, NOTIFY_STATUS,
} from '../actions/CommonActions'

const initialState = {
    hrList: [],
    notifyStatus: {},
}

const CommonReducer = function (state = initialState, action) {
    switch (action.type) {
    case GET_HR_PLATFORM_LIST: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case NOTIFY_STATUS: {
        return {
            ...state,
            notifyStatus: {
                ...action.payload,
            },
        }
    }
    default: {
        return {
            ...state,
        }
    }
    }
}

export default CommonReducer
