import Mock from './mock'

import './db/auth'
import './db/invoice'
import './db/calendarEvents'
import './db/users'
import './db/todos'
import './db/list'
import './db/products'
import './db/members'
import './db/tags'
import './db/images'
import './db/cart'

Mock.onAny().passThrough()
