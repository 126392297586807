import '../fake-db'
import React from 'react'
import { Store } from './redux/Store'
import { Provider } from 'react-redux'
import { AllPages } from './routes/routes'
import { useRoutes } from 'react-router-dom'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import './utils/i18nextInit'
import { TechpathTheme } from './theme/TechpathTheme'
import { AppProvider } from './contexts/AppContext'

const App = () => {
    const all_pages = useRoutes(AllPages())

    return (
        <Provider store={Store}>
            <SettingsProvider>
                <TechpathTheme>
                    <AuthProvider>
                        <AppProvider>
                            {all_pages}
                        </AppProvider>
                    </AuthProvider>
                </TechpathTheme>
            </SettingsProvider>
        </Provider>
    )
}

export default App
