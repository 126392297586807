import axiosJs from 'axios.js'
import axios2 from 'axios'
import { get } from 'lodash'

export const UPLOAD_IAMGE = "UPLOAD_IAMGE";

export const uploadImage = (imageData, file, type) => {
    return (dispatch) => {
        axiosJs.post('/site/generate-pre-signed-url', imageData).then(async response => {
            const { signedUrl } = get(response, "data.data");

            await axios2.put(signedUrl, file, {
                headers: {
                    'Content-Type': file.type,
                },
            });
            
            dispatch({
                type: UPLOAD_IAMGE,
                payload: {
                    imagePath: file.name,
                    imageType: type,
                },
            })
        })
    }
}
