import {
    GET_FUNDERS_LIST,
    GRANT_PERMISSION_FOR_FUNDER,
} from '../actions/FunderActions'

const initialState = {
    status: null,
    fundersList: [],
}

const FunderReducer = function (state = initialState, action) {
    switch (action.type) {
    case GET_FUNDERS_LIST: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case GRANT_PERMISSION_FOR_FUNDER: {
        return {
            ...state,
            ...action.payload,
        }
    }
    default: {
        return {
            ...state,
        }
    }
    }
}

export default FunderReducer
