
import { Avatar, Box, Divider, Grid, Stack, Autocomplete, TextField, styled } from '@mui/material'
import React, { useState } from 'react'
import Tag, { StatusTag } from 'app/components/Tag/Tag'
import { get, cloneDeep, uniqBy } from 'lodash'
import { Text } from 'app/components/Typography'
import { useNavigate } from 'react-router-dom'
import Button from 'app/components/button/Button'
import GenericDialog from 'app/components/Dialog/GenericDialog'
import { useDispatch, useSelector } from 'react-redux'
import { AddUserTags, DeleteUserTags } from "app/redux/actions/UserTagActions";
import { useTranslation } from 'react-i18next';

const Card = styled(Box)(({ theme }) => ({
    padding: `${theme.spacing(3.5)} 0`,
    ":hover": {
        cursor: 'pointer',
        backgroundColor: theme.palette.offWhite.main,
    },
}))

const Member = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
    },
}))

const MemberContent = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        flex: 1,
    },
}))

const ProfilePicture = styled(Avatar)(() => ({
    width: 100,
    height: 100,
    background: 'rgba(36, 36, 36, 0.2)',
    color: '#717171',
    fontSize: 36,
    fontWeight: 500,
}))

const AutoComplete = styled(Autocomplete)(() => ({
    width: '100%',
    '.MuiOutlinedInput-root': {
        padding: '0 39px 0 0 !important',
    },
}))

const TagsContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        marginLeft: 120,
    },
}))

const Tags = styled(Stack)(({ theme }) => ({
    marginTop: `${theme.spacing(1.5)}`,
    [theme.breakpoints.down('sm')]: {
        display: 'block',
        '.MuiChip-root': {
            margin: 5,
        },
    },
}))

const AddTags = styled(Stack)(({ theme }) => ({
    marginTop: `${theme.spacing(1.5)}`,
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        '.MuiButton-root': {
            height: '-webkit-fill-available',
        },
    },
}))

export const TeamMemberCard = ({ member }) => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const tagList = useSelector((state) => state.tags.tagList)

    const { id, firstName, surname, department, jobTitle, products, profilePic, role, tags } = member;

    const [userTags, setUserTags] = useState(tags || []);
    const [newTags, setNewTags] = useState([]);
    const [enableAddTags, setEnableAddTags] = useState(false);
    const [deleteTagId, setDeleteTagId] = useState(null);

    const handleEnableAddTags = () => {
        setEnableAddTags(!enableAddTags);
    }

    const handleTagDelete = async (tagId) => {
        await dispatch(DeleteUserTags(id, {
            tag: tagId,
        }));
        let clonedUserTags = cloneDeep(userTags);
        const updatedTags = await clonedUserTags.filter(tag => tag.id !== tagId)
        setUserTags(updatedTags)
        setDeleteTagId(null);
    }

    const handleTagAdd = async () => {
        await dispatch(AddUserTags(id, {
            tags: newTags.map((tag) => tag.id),
        }));
        setUserTags(uniqBy([...userTags, ...newTags], 'id'))
        handleEnableAddTags();
    }

    const handleChangeAutoSelect = (value) => {
        setNewTags(value);
    }

    const onClickMemberCard = () => {
        navigate(`/team-and-approvals/member/${id}/equipments`, { state: { member: { ...member, tags: userTags } } })
    }

    return (
        <Card data-testid="member-card" key={id}>
            <GenericDialog
                open={!!deleteTagId}
                handleClose={() => setDeleteTagId(null)}
                handleAccept={() => handleTagDelete(deleteTagId)}
                Message={t('AreYouSureYouWantToDeleteThis')}
            />
            <Member container columnSpacing={3} >
                <Grid item xs={'auto'} onClick={() => onClickMemberCard()}>
                    <ProfilePicture data-testid="profile-image" alt={`${firstName} ${surname}`} src={profilePic}>
                        {firstName.substring(0, 1)}
                    </ProfilePicture>
                </Grid>
                <MemberContent item xs={10}>
                    <Grid className='profile-overview' onClick={() => onClickMemberCard()}>
                        {(role === 'admin') && <StatusTag label={t("Admin")} variant="outlined" sx={{ marginBottom: 0.5 }} />}
                        <Text data-testid="member-name" fontWeight={600} marginBottom={0.5} >{`${firstName} ${surname}`}</Text>
                        <Text data-testid="member-title">{jobTitle} in {department}</Text>
                        <Stack className='budget-overview' direction={{ xs: 'column', sm: 'row' }} columnGap={1} divider={<Divider orientation="vertical" flexItem />}>
                            <Text data-testid="products-held">{get(products, 'held', 0)} {t('ProductsHeld')}</Text>
                            {/* TODO: POST MVP */}
                            {/* <Text data-testid="budget">{budget}</Text> */}
                            {get(products, 'pending', false) && <StatusTag data-testid="pending-approval-tag" label={`${get(products, 'pending', 0)} ${t('RequestWaitingApproval')}`} color="warning" variant="outlined" />}
                        </Stack>
                    </Grid>
                </MemberContent>
            </Member>
            <TagsContainer>
                <Tags direction="row" columnGap={1}>
                    {
                        userTags && userTags.map(tag => {
                            return <Tag
                                label={tag.name}
                                key={tag.id}
                                {...!get(tag, 'isDefault', false) ? {
                                    onDelete: () => setDeleteTagId(tag.id),
                                } : {}
                                }
                            />
                        })
                    }
                    <Button
                        variant="outlined"
                        color="black"
                        onClick={() => handleEnableAddTags()}
                        sx={{ fontWeight: 500 }}
                        size="small"
                        disabled={enableAddTags}
                    >{t('AddTags')}</Button>
                </Tags>
                <Grid item>
                    {enableAddTags && (
                        <AddTags direction="row" columnGap={1}>
                            <AutoComplete
                                multiple
                                id="tags-outlined"
                                options={tagList}
                                getOptionLabel={(option) => option.name}
                                onChange={(id, value) => handleChangeAutoSelect(value)}
                                value={newTags}
                                disableCloseOnSelect={true}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                            <Button variant="contained" color="success" onClick={() => handleTagAdd()} sx={{ fontWeight: 500 }} size="small">{t('Save')}</Button>
                            <Button variant="outlined" color="black" onClick={() => handleEnableAddTags()} sx={{ fontWeight: 500 }} size="small">{t('Cancel')}</Button>
                        </AddTags>
                    )}
                </Grid>
            </TagsContainer>
        </Card>
    )
}
