import useAuth from 'app/hooks/useAuth';
import { flat, isFeatureAllowedUser } from 'app/utils/utils';
import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AllPages } from 'app/routes/routes';
import Unauthorized from 'app/views/sessions/Unauthorized';
import { authRoles } from 'app/auth/authRoles';

const getUserRoleAuthStatus = (pathname, user, routes) => {
    if (!user || user.role.length === 0) {
        return false;
    }

    const roles = user.role;

    if (roles.includes(authRoles.superAdmin)) {
        return true;
    }

    const matched = routes.find((r) => r.path === pathname);

    if (matched && matched.auth && matched.auth.includes(authRoles.all)) {
        return true;
    }

    const authenticated =
        matched && matched.auth && matched.auth.length
            ? matched.auth.includes(roles[0])
            : true;

    return authenticated;
}

const isAllowedInRestrictedFeature = (pathname, user, routes) => {
    if (!user) {
        return false;
    }

    const matched = routes.find((r) => r.path === pathname);

    if (matched?.restrictedFeature) {
        return isFeatureAllowedUser(user);
    }

    return true;
}

const AuthGuard = ({ children }) => {
    const { isAuthenticated, user } = useAuth();

    const [previouseRoute, setPreviousRoute] = useState(null);
    const { pathname } = useLocation();
    const routes = flat(AllPages());

    const isUserRoleAuthenticated = getUserRoleAuthStatus(
        pathname,
        user,
        routes,
    )

    const isAllowedInRestricted = isAllowedInRestrictedFeature(
        pathname,
        user,
        routes,
    )

    useEffect(() => {
        if (previouseRoute !== null) setPreviousRoute(pathname)
    }, [pathname, previouseRoute]);

    if (isAuthenticated && !isAllowedInRestricted) return <>{<Unauthorized />}</>
    else if (isAuthenticated && isUserRoleAuthenticated) return <>{children}</>
    else if (isAuthenticated && !isUserRoleAuthenticated) return <>{<Unauthorized />}</>
    else {
        return (
            <Navigate
                to="/signin"
                state={{ redirectUrl: previouseRoute }}
            />
        )
    }
}

export const PreAuthGuard = ({ children }) => {
    const { isAuthenticated } = useAuth();
    let authenticated = isAuthenticated;

    return (!authenticated) ? <>{children}</> : <Navigate to="/" />
}

export default AuthGuard;
