import axios from 'axios.js'
import { get } from 'lodash'
import { NOTIFY_STATUS } from './CommonActions'

export const GET_TAGS_LIST = 'GET_TAGS_LIST'
export const DELETE_TAG = 'DELETE_TAG'
export const UPDATE_TAG = 'UPDATE_TAG'
export const CREATE_TAG = 'CREATE_TAG'

export const getTags = (callback) => {
    return async (dispatch) => {
        try {
            const response = await axios.get('/tag');
            const tags = get(response, "data.data");

            dispatch({
                type: GET_TAGS_LIST,
                payload: tags,
            })

            callback?.(response)
            
        } catch (error) {
            dispatch({
                type: NOTIFY_STATUS,
                payload: {
                    message: error.message,
                    variant: 'error',
                },
            })
        }
    }
}

export const createTag = (tag, callback) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(`/tag`, { ...tag })
            if(response.status === 200) {
                const tagResponse = await axios.get('/tag');
                const tags = get(tagResponse, "data.data");
                dispatch({
                    type: GET_TAGS_LIST,
                    payload: tags,
                })
                dispatch({
                    type: NOTIFY_STATUS,
                    payload: {
                        message: response.data.message,
                        variant: 'success',
                    },
                })
            }
            callback?.(null, response)
        } catch (error) {
            if(error.message) {
                dispatch({
                    type: NOTIFY_STATUS,
                    payload: {
                        message: error.message,
                        variant: 'warning',
                    },
                })
            }
            callback?.(error, null)
        }
    }
}


export const updateTag = (tag, callback) => {
    return async (dispatch) => {
        try {
            const response = await axios.put(`/tag/${tag.id}`, { name: tag.name })
            if(response.status === 200) {
                dispatch({
                    type: UPDATE_TAG,
                    payload: tag,
                })
                dispatch({
                    type: NOTIFY_STATUS,
                    payload: {
                        message: response.data.message,
                        variant: 'success',
                    },
                })
            }
            callback?.(null, response)
        } catch (error) {
            if(error.message) {
                dispatch({
                    type: NOTIFY_STATUS,
                    payload: {
                        message: error.message,
                        variant: 'warning',
                    },
                })
            }
            callback?.(error, null)
        } 
    }
}



export const deleteTag = (tagId, callback) => {
    return async (dispatch) => {
        try {
            const response = await axios.delete(`/tag/${tagId}`)
            if(response.status === 200) {
                dispatch({
                    type: DELETE_TAG,
                    payload: {
                        tagId,
                    },
                })
                dispatch({
                    type: NOTIFY_STATUS,
                    payload: {
                        message: response.data.message,
                        variant: 'success',
                    },
                })
            }
            callback?.(null, response)
        } catch (error) {
            if(error.message) {
                dispatch({
                    type: NOTIFY_STATUS,
                    payload: {
                        message: error.message,
                        variant: 'warning',
                    },
                })
            }
            callback?.(error, null)
        } 
    }
}