import {
    GET_USER_PRODUCTS,
    UPDATE_USER_PRODUCT_STATUS,
} from '../actions/UserProductActions'

const initialState = {
    userProducts: [],
    updatedProductId: null,
}

const UserProductsReducer = function (state = initialState, action) {
    switch (action.type) {
    case GET_USER_PRODUCTS: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case UPDATE_USER_PRODUCT_STATUS: {
        return {
            ...state,
            ...action.payload,
        }
    }
    default: {
        return {
            ...state,
        }
    }
    }
}

export default UserProductsReducer
