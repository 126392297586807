import React, { lazy } from "react";
import Loadable from "app/components/Loadable/Loadable";
const CompanyList = Loadable(lazy(() => import("./company/CompanyList")));
const CompanyDetails = Loadable(lazy(() => import("./company/CompanyDetails")));
const FundersList = Loadable(lazy(() => import("./funders/FundersList")));
import { authRoles } from "app/auth/authRoles";

const fundersDashboardRoutes = [
    {
        path: 'companies',
        element: <CompanyList />,
        auth: [authRoles.all],
        level: 1,
    },
    {
        path: 'company-details',
        element: <CompanyDetails />,
        auth: [authRoles.all],
        level: 1,
    },
    {
        path: 'company-analysis/:companyId/:organisationName?',
        element: <CompanyDetails />,
        auth: [authRoles.all],
        level: 1,
    },
    {
        path: 'funders',
        element: <FundersList />,
        auth: [authRoles.all],
        level: 1,
    },
];

export default fundersDashboardRoutes;
