import React, { lazy } from "react";
import Loadable from "app/components/Loadable/Loadable";
const ApprovedProducts = Loadable(lazy(() => import("../approved-products/ApprovedProducts")));
import { authRoles } from "app/auth/authRoles";

const approvedProductsRoutes = [
    {
        path: "/approved-products",
        element: <ApprovedProducts />,
        auth: [authRoles.superAdmin, authRoles.siteAdmin, authRoles.smeAdmin],
        level: 1,
    },
];

export default approvedProductsRoutes;
