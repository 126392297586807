import axios from 'axios.js'
import { get } from 'lodash'
import { setMessage } from "app/redux/actions/MessageActions";
import { setLoading } from 'app/redux/actions/LoadingActions'

export const GET_USER_PRODUCTS = 'GET_USER_PRODUCTS'
export const UPDATE_USER_PRODUCT_STATUS = 'UPDATE_USER_PRODUCT_STATUS'

export const getUserProducts = (smeId, userId, memberId) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.get(`/product/${smeId}/approver/${userId}`, { params: { approveeUserId: memberId } });
            dispatch({
                type: GET_USER_PRODUCTS,
                payload: {
                    userProducts: get(response, 'data.data', []),
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export const updateUserProductStatus = (data, productId, customId) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.put(`/product/${encodeURIComponent(productId)}/approvalStatus`, data);

            dispatch(setMessage({ message: get(response, "data.message", ""), variant: "success" }));
            dispatch({
                type: UPDATE_USER_PRODUCT_STATUS,
                payload: {
                    updatedProductId: customId,
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}
