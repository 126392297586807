import { Button, Tooltip, CircularProgress, Box, styled } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const StyledProgress = styled(CircularProgress)(() => ({
    position: 'absolute',
    top: '10px',
    left: '43%',
}))

const CustomButton = (props) => {
    const {
        id,
        label = '',
        onClick = () => {},
        isSubmit = false,
        disabled,
        type = "primary",
        tooltip = "",
        style = {},
        variant = "contained",
        loading = false,
    } = props;

    return (
        <Tooltip title={tooltip}>
            <Box position="relative">
                <Button
                    variant={variant}
                    onClick={!isSubmit ? () => onClick() : () => {}}
                    color={type}
                    disabled={disabled || loading}
                    id={id}
                    style={style}
                    type={isSubmit ? "submit" : "button"}
                >
                    {label}
                </Button>
                {loading && (
                    <StyledProgress
                        size={24}
                        className="buttonProgress"
                    />
                )}
            </Box>
        </Tooltip>
    );
};

CustomButton.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    isSubmit: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.oneOf(["primary", "secondary"]),
    style: PropTypes.object,
    tooltip: PropTypes.string,
};

export default CustomButton;
