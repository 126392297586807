import axios from 'axios.js'
import { get } from 'lodash'
import { setLoading } from "app/redux/actions/LoadingActions";
import { setMessage } from "app/redux/actions/MessageActions";

export const GET_SMES_BY_SITE_ID = "GET_SMES_BY_SITE_ID";
export const RAILZ_RESET_CONNECTION = "RAILZ_RESET_CONNECTION";

export const getSmesBySiteId = (siteId) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.get(`/site/sme-list/${siteId}`);

            dispatch({
                type: GET_SMES_BY_SITE_ID,
                payload: {
                    smeList: get(response, "data.data", []),
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export const resetRailzConnection = () => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.put('railz/reset-connection');

            await dispatch(setMessage({ message: get(response, "data.message", ""), variant: "success" }));
            dispatch({
                type: RAILZ_RESET_CONNECTION,
                payload: {
                    railz: get(response, "data.data", []),
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}
