import React, { lazy } from "react";
import Loadable from "app/components/Loadable/Loadable";
import { authRoles } from "app/auth/authRoles";

const MyEquipments = Loadable(lazy(() => import("./MyEquipments")));
const RequestProducts = Loadable(lazy(() => import("../request-products/RequestProducts")));

const myEquipmentsRoutes = [
    {
        path: "/my-equipments",
        element: <MyEquipments />,
        auth: [authRoles.all],
        level: 1,
    },
    {
        path: '/my-equipments/request-products',
        element: <RequestProducts />,
        auth: [authRoles.all],
        level: 2,
    },
];

export default myEquipmentsRoutes;
