import Mock from '../mock'

const members = {
    data: [
        {
            id: 2,
            name: 'Simon Bowler',
            firstName: 'Simon',
            surName: "Bowler",
            profileImage: 'https://randomuser.me/api/portraits/men/60.jpg',
            title: 'Senior UX Desginer in Product',
            productsHeld: 0,
            budget: 'Budget used £45 of £60 /mo.',
            tags: ['Everyone'],
            isAdmin: false,
            pendingApproval: 1,
            adminId: 1,
        },
        {
            id: 3,
            name: 'Jane Doe',
            firstName: 'Jane',
            surName: "Doe",
            profileImage: 'https://randomuser.me/api/portraits/women/60.jpg',
            title: 'Senior HR Manager in Operations',
            productsHeld: 0,
            budget: 'Budget used £45 of £60 /mo.',
            tags: ['Everyone', 'Designer'],
            isAdmin: true,
            adminId: 1,
        },
        {
            id: 4,
            name: 'Mam Mwanajuma',
            firstName: 'Mam',
            surName: "Mwanajuma",
            profileImage: 'https://randomuser.me/api/portraits/men/61.jpg',
            title: 'Operations Manager in Operations',
            productsHeld: 0,
            budget: 'Budget used £45 of £60 /mo.',
            tags: ['Everyone'],
            isAdmin: false,
            adminId: 1,
        },
        {
            id: 5,
            name: 'Mohamed Bandara',
            firstName: 'Mohamed',
            surName: "Bandara",
            profileImage: 'https://randomuser.me/api/portraits/men/62.jpg',
            title: 'Backend Engineer in Development',
            productsHeld: 0,
            budget: 'Budget used £45 of £60 /mo.',
            tags: ['Developer'],
            isAdmin: false,
            pendingApproval: 2,
            adminId: 1,
        },
        {
            id: '01G8ZD3PG3SDJR0C7CNPC0E5F9',
            name: 'Simon Bowler',
            firstName: 'Simon',
            surname: "Bowler",
            profileImage: 'https://randomuser.me/api/portraits/men/60.jpg',
            products: { held: 2, pending: 1 },
            role: "member",
            jobTitle: "Senior UX Desginer",
            department: "Product",
            tags: [],
        },
    ],
}

Mock.onGet('/api/members').reply(() => {
    return [200, members]
})