import React, { useEffect } from 'react'
import { Backdrop, CircularProgress, styled } from "@mui/material";
import { MatxLayouts } from './index'
import { MatxSuspense } from 'app/components'
import useSettings from 'app/hooks/useSettings'
import { useSelector } from 'react-redux'
import useAuth from 'app/hooks/useAuth'
import { useNavigate, useLocation } from 'react-router-dom'
import { isAdmin } from 'app/utils/utils'

const StyledBackdrop = styled(Backdrop)(() => ({
    zIndex: 1,
}))

const StyledCircularProgress = styled(CircularProgress)(() => ({
    position: "absolute",
    top: "50%",
    left: "45%",
    zIndex: 2,
}))

const MatxLayout = (props) => {
    const loading = useSelector((state) => state.loading)
    const { settings } = useSettings()
    const navigate = useNavigate()
    const { isAuthenticated, isOrganisationSetuped, user } = useAuth()
    const Layout = MatxLayouts[settings.activeLayout]
    const location = useLocation();

    useEffect(() => {
        const { pathname } = location;
        if (isAdmin() && !isOrganisationSetuped && isAuthenticated && pathname !== 'get-started') {
            navigate('/get-started')
        }
    }, [location, isAuthenticated, isOrganisationSetuped, user]);

    return (
        <>
            <StyledBackdrop open={loading}></StyledBackdrop>
            {loading && <StyledCircularProgress size={48} color="primary" />}
            <MatxSuspense>
                <Layout {...props} />
            </MatxSuspense>
        </>
    )
}

export default MatxLayout
