import axios from 'axios'
import { get, unescape } from 'lodash'
import { setMessage } from "app/redux/actions/MessageActions";

export const GET_AI_ANSWER = "GET_AI_ANSWER";
export const GET_AI_RESOURCES = "GET_AI_RESOURCES";
export const GET_LABEL_SETS = "GET_LABEL_SETS";
export const CLEAR_AI_RESULTS = "CLEAR_AI_RESULTS";

const URLS = {
    endpoint: "https://europe-1.nuclia.cloud/api/v1/kb/c66801af-6690-457c-a127-770c0fb1cbb5/",
    chat: "chat",
    find: "find",
    labelsets: "labelsets",
    feedback: "feedback",
};

const findIndexOfSentenceStart = (str) => {
    const firstSpaceIndex = str.indexOf(' ');

    for (let i = firstSpaceIndex; i > 0; i--) {
        if (str[i] >= 'A' && str[i] <= 'Z') {
            return i;
        }
    }

    return -1; // Indicates no capital letter after the first space
};

const extractDataFromToken = (token) => {
    let decodedData = null;

    try {
        const encodedData = token.substring(4);
        const indexOfSentenceStart = findIndexOfSentenceStart(encodedData);
        const extractedToken = encodedData.slice(0, indexOfSentenceStart);
        const extractedText = encodedData.slice(indexOfSentenceStart, encodedData.indexOf('_END_'));

        // eslint-disable-next-line no-undef
        let decodedString = Buffer.from(extractedToken, 'base64').toString('utf-8');
        decodedData = JSON.parse(decodedString);
        const resources = Object.values(get(decodedData, "resources", {})).flatMap(item => item);

        return {
            text: extractedText,
            data: decodedData,
            resources,
        }
    } catch (error) {
        console.log(error);
    }

    return decodedData;
};

export const getAiAnswer = (params) => {
    return async (dispatch) => {
        const data = {
            "query":"document",
            "context":[],
            "show":["basic","values","origin"],
            "features":["paragraphs","relations"],
            "inTitleOnly":false,
            "highlight":true,
            "autofilter":false,
            "page_number":0,
            "filters":[],
            ...params,
        }
        try {
            const response = await axios.post(`${URLS.endpoint}${URLS.chat}`, data);

            const learningId = get(response, "headers.nuclia-learning-id", "");
            const extractedData = extractDataFromToken(unescape(get(response, "data", "")));

            dispatch({
                type: GET_AI_ANSWER,
                payload: {
                    chat: {
                        ...extractedData,
                        learningId,
                    },
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error?.message, variant: "error" }));
        }
    }
}

export const getResources = (params) => {
    return async (dispatch) => {
        const data = {
            "query":"document",
            "features":["paragraph","vector"],
            "shards":[],
            "highlight":true,
            "autofilter":false,
            "page_number":0,
            "show":["basic","values","origin"],
            "filters":[],
            ...params,
        }
        try {
            const response = await axios.post(`${URLS.endpoint}${URLS.find}`, data);
            const resources = Object.values(get(response, "data.resources", {})).flatMap(item => item);

            dispatch({
                type: GET_AI_RESOURCES,
                payload: {
                    resources,
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error?.message, variant: "error" }));
        }
    }
}

export const getLabelSets = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`${URLS.endpoint}${URLS.labelsets}`);
            const labelList = Object.entries(get(response, "data.labelsets", {})).flatMap(([itemName, item]) => {
                const labelsWithItemName = item.labels.map(label => ({
                    ...label,
                    labelset: itemName,
                    id: `/l/${itemName}/${label.title}`,
                }));

                return labelsWithItemName;
            });

            dispatch({
                type: GET_LABEL_SETS,
                payload: {
                    labelsets: labelList,
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error?.message, variant: "error" }));
        }
    }
}

export const provideFeedback = (isGood, learningId) => {
    return async (dispatch) => {
        const data = {
            ident: learningId, // Nuclia-Learning-Id
            good: isGood,
            task: "CHAT",
            feedback: "",
        };

        try {
            await axios.post(`${URLS.endpoint}${URLS.feedback}`, data);
        } catch (error) {
            dispatch(setMessage({ message: error?.message, variant: "error" }));
        }
    }
}

export const clearAiResults = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_AI_RESULTS,
            payload: {
                chat: {},
                resources: [],
            },
        })
    }
}
