import Mock from '../mock'

Mock.onGet('/api/upload-image').reply(() => {
    return [200, 
        {
            data: {
                imagePath: 'https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg',
            },
        },
    ]
})
