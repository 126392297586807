import { SET_IS_CHECKOUT_INPROGRESS, CART_CHECKOUT } from '../actions/CartActions'

const initialState = {
    // isCheckoutInprogress: localStorage.getItem('isCheckoutInprogress') === 'true', // TODO: Temp remove
    isCheckoutInprogress: false,
    paymentInfo: {},
};

const CartReducer = function (state = initialState, action) {
    switch (action.type) {
    case SET_IS_CHECKOUT_INPROGRESS: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case CART_CHECKOUT: {
        return {
            ...state,
            ...action.payload,
        }
    }
    default: {
        return {
            ...state,
        }
    }
    }
};

export default CartReducer;
