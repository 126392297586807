import React, { lazy } from "react";
import TeamAndApprovals from "./TeamAndApprovals";
import Loadable from "app/components/Loadable/Loadable";
import { authRoles } from "app/auth/authRoles";

const RequestProducts = Loadable(lazy(() => import("app/views/request-products/RequestProducts")));
const TeamMemberEquipments = Loadable(lazy(() => import("app/views/team-amd-approvals/TeamMemberEquipments")));

const teamAndApprovalsRoutes = [
    {
        path: "/team-and-approvals",
        element: <TeamAndApprovals />,
        auth: [authRoles.all],
        level: 1,
    },
    {
        path: "/team-and-approvals/member/:memberId/equipments",
        element: <TeamMemberEquipments />,
        auth: [authRoles.all],
        level: 2,
        isCheckout: true,
    },
    {
        path: "/team-and-approvals/member/:memberId/equipments/:status",
        element: <TeamMemberEquipments />,
        auth: [authRoles.all],
        level: 2,
        isCheckout: true,
    },
    {
        path: '/team-and-approvals/member/:memberId/request-products',
        element: <RequestProducts />,
        auth: [authRoles.all],
        level: 3,
        isCheckout: true,
    },
];

export default teamAndApprovalsRoutes;
