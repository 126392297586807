import axios from 'axios.js'
import { get } from 'lodash'
import { setLoading } from "app/redux/actions/LoadingActions";
import { setMessage } from "app/redux/actions/MessageActions";

export const GET_WIDGET_LIST = "GET_WIDGET_LIST";
export const UPDATE_WIDGET_TAGS = "UPDATE_WIDGET_TAGS";

export const getWidgets = () => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.get('/widget/list');

            const widgets = get(response, "data.widgets", []).map(widget => {
                if (widget.type === "upswot") {
                    return {
                        ...widget,
                        widgetTag: get(widget, "configurations.tag_name", ''),
                        api: get(widget, "configurations.js_path", ''),
                        config: get(response, "data.upswot_configurations", ''),
                    }
                }
                if (widget.type === "railz") {
                    return {
                        ...widget,
                        connectionId: get(response, "data.railz_configurations.connection_id", ''),
                        railzToken: get(response, "data.railz_configurations.token", ''),
                        connectionUrl: get(response, "data.railz_configurations.connection_url", ''),
                    }
                }

                return widget
            })

            dispatch({
                type: GET_WIDGET_LIST,
                payload: {
                    widgets,
                    railzConnectionId: get(response, "data.railz_configurations.connection_id", ''),
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export const UpdateWidgetTags = (widgetId, data) => {
    return (dispatch) => {
        axios.post(`/widget/${widgetId}/tags`, data).then(response => {
            dispatch(setMessage({
                message: get(response, 'message', 'Tags Updated'),
                variant: "success",
            }));

            return response
        }).catch((error) => {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        });
    }
}
