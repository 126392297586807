import React, { lazy } from 'react';
import Loadable from 'app/components/Loadable/Loadable';
import { authRoles } from 'app/auth/authRoles';

const Funding = Loadable(lazy(() => import("./Funding")));

const fundingRoutes = [
    {
        path: '/funding',
        element: <Funding />,
        auth: [authRoles.all],
        level: 1,
    },
];

export default fundingRoutes;
