import React from 'react';
import { Box, Divider } from '@mui/material';
import { ProductCard } from './ProductCard';

export const ProductList = ({ products }) => {
    return <div>
        {
            products && products.map((product, index) => {
                return <Box key={index}>
                    <ProductCard product={product} />
                    <Divider />
                </Box>
            })
        }
    </div>
}
