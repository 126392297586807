import axios from 'axios.js'
import { get } from 'lodash'
import { setLoading } from "app/redux/actions/LoadingActions";
import { setMessage } from "app/redux/actions/MessageActions";

export const GET_FUNDERS_LIST = "GET_FUNDERS_LIST";
export const GRANT_PERMISSION_FOR_FUNDER = "GRANT_PERMISSION_FOR_FUNDER";

export const getFunderList = () => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.get('funder/list');

            dispatch({
                type: GET_FUNDERS_LIST,
                payload: {
                    status: null,
                    fundersList: get(response, "data.data.items", []),
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export const grantPermissionForFunder = (data) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.put('funder/grant-permission', data);

            dispatch({
                type: GRANT_PERMISSION_FOR_FUNDER,
                payload: {
                    status: get(response, "data.data.status", null),
                },
            })
            dispatch(setMessage({ message: response?.data?.message, variant: "success" }));
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}
