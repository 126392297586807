import {
    GET_COMPANIES_BY_SITE_ID,
    GET_COMPANY_DETAILS_BY_ID,
    GET_REPORT_DATA_BY_SME_ID,
    CLEAR_REPORT_DATA,
    REQUEST_PERMISION_FOR_COMPANY,
} from '../actions/CompanyActions'

const initialState = {
    companyList: [],
    companyDetails: null,
    smeReportsDataAccounting: [],
    smeReportsDataAnalytics: [],
    status: null,
}

const CompanyReducer = function (state = initialState, action) {
    switch (action.type) {
    case GET_COMPANIES_BY_SITE_ID: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case GET_COMPANY_DETAILS_BY_ID: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case GET_REPORT_DATA_BY_SME_ID: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case CLEAR_REPORT_DATA: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case REQUEST_PERMISION_FOR_COMPANY: {
        return {
            ...state,
            ...action.payload,
        }
    }
    default: {
        return {
            ...state,
        }
    }
    }
}

export default CompanyReducer
