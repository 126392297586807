/* eslint-disable no-unused-vars */
import Mock from '../mock'

export const tags = [
    {
        id: "01G741FCJPP68N2TZRPFE36SVA",
        name: "Everyone",
    },
    {"id":"01G741FCJPP68N2TZRPFE36SVB","name":"DEV"},
    {"id":"01G741FCJPP68N2TZRPFE36SVC","name":"QA"},
]
Mock.onGet('/api/tags').reply(() => {
    return [200, tags]
})

Mock.onGet('/api/tags-list').reply(() => {
    return [200, 
        {
            data: {
                tags: tags,
            },
        },
    ]
})

Mock.onPatch('/api/tags').reply(config => {
    return new Promise(function(resolve) {
        setTimeout(function() {
            const data = JSON.parse(config.data);
            const tag = data.tag
            const tags = data.tags

            const index = tags.findIndex(t => t.id === tag.id)

            if (index > -1) { 
                tags[index] = {...tags[index], ...tag}
            }
            resolve([200, { message: 'OK', result: tags }]);
        }, 1000);
    });
});

Mock.onDelete('/api/tags').reply(config => {
    return new Promise(function(resolve) {
        setTimeout(function() {
            const tag = config.tag
            const tags = config.tags

            const index = tags.findIndex(t => t.id === tag.id)

            if (index > -1) { 
                tags.splice(index, 1);
            }

            console.log({ tags })
            
            resolve([200, { message: 'OK', result: tags }]);
        }, 1000);
    });
});


Mock.onPost('/api/tags').reply(config => {
    return new Promise(function(resolve) {
        setTimeout(function() {
            const data = JSON.parse(config.data);
            const name = data.name

            const newTag = { id: tags.length + 1, name }

            tags.push(newTag)
            
            resolve([200, { message: 'OK', result: newTag }]);
        }, 1000);
    });
});
