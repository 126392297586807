import Mock from '../mock'

Mock.onPost('/api/checkout').reply(() => {
    return [200, 
        {
            data: {
                stripe: 'https://checkout.stripe.com/pay/cs_test_b1Jbd3Z9dJRej9l76omZEVg4VbhDpTPj7783nHFjNZSD6lOrrOnh8TY01j#fidkdWxOYHwnPyd1blpxYHZxWjA0SU5gdEhOPW9GYEFgV1RmT0xRZk1gdFFWd102R0hTM0R0MlB8cWIwYnZfb0IyZGRTRHFOQ0ZOZk82aWBQVHJRcFRrS19hbmluZm1oc0NrMmdzTjRDbEpSNTVfaXVkb3FENCcpJ2N3amhWYHdzYHcnP3F3cGApJ2lkfGpwcVF8dWAnPydocGlxbFpscWBoJyknYGtkZ2lgVWlkZmBtamlhYHd2Jz9xd3BgeCUl',
            },
        },
    ]
})
