import axios from 'axios.js'
import { get, pick } from 'lodash'
import { NOTIFY_STATUS } from './CommonActions'

export const GET_SME_PRODUCT_LIST = 'GET_SME_PRODUCT_LIST'
export const UPDATE_SME_PRODUCT_LIST = 'UPDATE_SME_PRODUCT_LIST'
export const GET_USER_PRODUCT_LIST = 'GET_USER_PRODUCT_LIST'
export const SME_PRODUCTS_FOR_REQUEST_PAGE = "SME_PRODUCTS_FOR_REQUEST_PAGE"

export const getSmeProductList = (smeId, callback) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/product/${smeId}`);
            const products = get(response, "data.data");

            // create new object to handle sections { "PHONE": [], "MONITOR": [] }
            const productsWithTypes = products.reduce((productTypes, item) => {
                if (item.type in productTypes) {
                    productTypes[item.type] = [...productTypes[item.type], item]
                } else {
                    productTypes[item.type] = [item]
                }
                return productTypes
            }, {})

            dispatch({
                type: GET_SME_PRODUCT_LIST,
                payload: productsWithTypes,
            })

            callback?.(response);


        } catch (error) {
            dispatch({
                type: NOTIFY_STATUS,
                payload: {
                    message: error.message,
                    variant: 'error',
                },
            })
        }
    }
}

export const getSmeProductListWithFilter = (smeId, tagName = "EVERYONE", callback) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/product/${smeId}/tag/${tagName}`);
            const products = get(response, "data.data");

            // create new object to handle sections { "PHONE": [], "MONITOR": [] }
            const productsWithTypes = products.reduce((productTypes, item) => {
                if (item.product.type in productTypes) {
                    productTypes[item.product.type] = [...productTypes[item.product.type], item]
                } else {
                    productTypes[item.product.type] = [item]
                }
                return productTypes
            }, {})

            dispatch({
                type: SME_PRODUCTS_FOR_REQUEST_PAGE,
                payload: productsWithTypes,
            })

            callback?.(response);

        } catch (error) {
            dispatch({
                type: NOTIFY_STATUS,
                payload: {
                    message: error.message,
                    variant: 'error',
                },
            })
        }
    }
}

export const requestProduct = ({ smeId, userId, requestPayload, callback, approveeUserId, isApprovee = false}) => {
    return async (dispatch) => {
        try {
            let endpointUrl = `/product/${smeId}/user/${userId}`;
            if (isApprovee) {
                endpointUrl = `/product/${smeId}/approver/${userId}?approveeUserId=${approveeUserId}`;
            }

            const response = await axios.put(endpointUrl, requestPayload);

            if(response.status === 200) {
                dispatch({
                    type: NOTIFY_STATUS,
                    payload: {
                        message: response.data.message,
                        variant: 'success',
                    },
                })
            }

            callback?.(response)
            
        } catch (error) {
            dispatch({
                type: NOTIFY_STATUS,
                payload: {
                    message: error.message,
                    variant: 'error',
                },
            })
        }
    }
}

export const getListOfUserProducts = ({ smeId, userId, callback }) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/product/${smeId}/user/${userId}`);
            const products = get(response, "data.data");

            const productsWithApprovalStatus = products.reduce((approvalStatus, item) => {
                if (item.approval_status in approvalStatus) {
                    approvalStatus[item.approval_status] = [...approvalStatus[item.approval_status], item]
                } else {
                    approvalStatus[item.approval_status] = [item]
                }
                return approvalStatus
            }, {})

            dispatch({
                type: GET_USER_PRODUCT_LIST,
                payload: productsWithApprovalStatus,
            })
            callback?.(response)
        } catch (error) {
            dispatch({
                type: NOTIFY_STATUS,
                payload: {
                    message: error.message,
                    variant: 'error',
                },
            })
        }
    }
}

export const addProductsTags = ({smeId, product, tags, selectedTags, callback }) => {
    return async (dispatch) => {
        try {
            const productPayload = pick(product, ['image', 'last_updated', 'price', 'rental_period', 'name', 'product_id', 'type', 'url'])
            
            const response = await axios.put(`/product/${smeId}`, {
                product: productPayload,
                tags,
            });

            if(response.status === 200) {
                dispatch({
                    type: UPDATE_SME_PRODUCT_LIST,
                    payload: {
                        product_id: product.product_id,
                        type: product.type,
                        tags,
                        selectedTags,
                    },
                })
                dispatch({
                    type: NOTIFY_STATUS,
                    payload: {
                        message: response.data.message,
                        variant: 'success',
                    },
                })
            }

            callback?.(response)
            
        } catch (error) {
            dispatch({
                type: NOTIFY_STATUS,
                payload: {
                    message: error.message,
                    variant: 'error',
                },
            })
            callback?.(error)
        }
    }
}

export const deleteProductsTags = ({smeId, productId, tag, callback }) => {
    return async (dispatch) => {
        try {            

            const response = await axios.delete(`/product/${smeId}`, {
                data: {
                    tag,
                    product_id: productId,
                },
            });

            if(response.status === 200) {
                dispatch({
                    type: NOTIFY_STATUS,
                    payload: {
                        message: response.data.message,
                        variant: 'success',
                    },
                })
            }

            callback?.(response)
            
        } catch (error) {
            dispatch({
                type: NOTIFY_STATUS,
                payload: {
                    message: error.message,
                    variant: 'error',
                },
            })
        }
    }
}
