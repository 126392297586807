import React, { createContext, useState, useEffect } from 'react'
import { merge, get } from 'lodash'
import { MatxLayoutSettings } from 'app/components/MatxLayout/settings'
import axios from 'axios.js'

const SettingsContext = createContext({
    settings: MatxLayoutSettings,
    updateSettings: () => { },
    siteSettings: {},
})

const siteSettingsObj = {
    id: '',
    name: '',
    api_path: '',
    theme: {
        logo: '',
        favicon: '',
        header_bar_color: '',
    },
    app_settings: {
        bscore_key: '',
        formwise_url: '',
        plai_url: '',
        privacy_policy_url: '#',
        terms_and_condition_url: '#',
        upswot_admin_host: '',
    },
}

export const SettingsProvider = ({ settings, children }) => {
    const [currentSettings, setCurrentSettings] = useState(
        settings || MatxLayoutSettings,
    )

    const [siteSettings, setSiteSettings] = useState(siteSettingsObj)

    useEffect(() => {
        (async () => {
            const settingsSet = await getSiteSettings();
            document.title = settingsSet.name

            let link = document.querySelector("link[rel~='icon']")
            if (!link) {
                link = document.createElement('link')
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link)
            }
            link.href = settingsSet.theme.favicon;

            localStorage.setItem('siteId', settingsSet.id);
        })();
    }, []);

    const handleUpdateSettings = (update = {}) => {
        const marged = merge({}, currentSettings, update)
        setCurrentSettings(marged)
    }

    const getSiteSettings = async () => {
        const response = await axios.get(`site/config/${window.location.hostname}`)
        // const response = await axios.get(`site/config/devapp.fintovo.com`)
        const allSettings = get(response, 'data', siteSettingsObj)

        setSiteSettings(allSettings)

        return allSettings
    }

    return (
        <SettingsContext.Provider
            value={{
                settings: currentSettings,
                updateSettings: handleUpdateSettings,
                siteSettings,
            }}
        >
            {children}
        </SettingsContext.Provider>
    )
}

export default SettingsContext
