import React, { useState, useRef } from 'react'
import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next';

export const TeamSearch = ({ setSearchKey }) => {
    const { t } = useTranslation();
    const timerRef = useRef(null);
    const [value, setValue] = useState("")

    const onChangeSearch = (e) => {
        setValue(e.target.value)
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(
            (searchTextValue) => {
                setSearchKey(searchTextValue);
            },
            500,
            e.target.value,
        );
    }

    return <TextField data-testid="member-search" id="outlined-basic" variant="outlined" fullWidth placeholder={t('SearchByName')} onChange={onChangeSearch} value={value} />
}
