import axios from 'axios.js';
import { get } from 'lodash';
import { setMessage } from "app/redux/actions/MessageActions";
import { setLoading } from 'app/redux/actions/LoadingActions'

export const SET_IS_CHECKOUT_INPROGRESS = "SET_IS_CHECKOUT_INPROGRESS";
export const CART_CHECKOUT = "CART_CHECKOUT";

export function setCheckoutInprogressStatus() {
    // Enable chackout buttons for now - MVP
    // TODO: Temp remove
    // localStorage.setItem('isCheckoutInprogress', status);

    return {
        type: SET_IS_CHECKOUT_INPROGRESS,
        payload: {
            isCheckoutInprogress: false,
            // isCheckoutInprogress: status,
        },
    };
}

export const payNowCart = (item, pathname) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            // const product = omit(item, ['instant_buy', 'description', 'last_updated', 'site_id']);
            // product.qty = 1;
            const product = {
                product_id: item.product_id,
                qty: 1,
                requestedAt: item.requestedAt,
            }

            const response = await axios.post('/checkout/pay-now', {
                product,
                cancelUrl: pathname,
                successUrl: `${pathname}/success`,
            })

            dispatch({
                type: CART_CHECKOUT,
                payload: {
                    paymentInfo: get(response, "data.data", {}),
                },
            });

            
            if (get(response, "data.data.paymentUrl", false)) {
                window.location.replace(get(response, "data.data.paymentUrl"));
            } else {
                // TODO: paymentUrl missing
            }
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
            dispatch(setCheckoutInprogressStatus(false));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export const checkoutCart = (cartDetails) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const cartItems = Object.keys(cartDetails).map(key => {
                return cartDetails[key];
            })

            const cartItemsData = cartItems.map(item => ({
                userId: item.userId,
                productId: item.product_id,
                requestedAt: item.requestedAt,
                qty: item.quantity,
            }))

            let pathname = location.pathname;

            if (pathname.endsWith('/fail')) {
                pathname = pathname.replace('/fail', '');
            }
            if (pathname.endsWith('/success')) {
                pathname = pathname.replace('/success', '');
            }

            const response = await axios.post('/checkout/session', {
                items: cartItemsData,
                cancelUrl: `${pathname}`,
                successUrl: `${pathname}/success`,
            })

            dispatch({
                type: CART_CHECKOUT,
                payload: {
                    paymentInfo: get(response, "data.data", {}),
                },
            });

            
            if (get(response, "data.data.paymentUrl", false)) {
                window.location.replace(get(response, "data.data.paymentUrl"));
            } else {
                // TODO: paymentUrl missing
            }
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
            dispatch(setCheckoutInprogressStatus(false));
        } finally {
            dispatch(setLoading(false))
        }
    }
}
