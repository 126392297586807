export const authRoles = {
    superAdmin: "SUPER_ADMIN",
    siteAdmin: "SITE_ADMIN",
    smeAdmin: "SME_ADMIN",
    smeStaff: "SME_STAFF",
    admin: "ADMIN",
    approver: "APPROVER",
    member: "MEMBER",
    all: "*",
}
