import { Box, styled } from '@mui/material'
import React from 'react'
import { Text } from 'app/components/Typography'
import { useTranslation } from 'react-i18next';

const Container = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.black.darker16}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: 'center',
    'img': {
        width: '100%',
        height: 'auto',
        maxWidth: '200px',
        padding: 20,
    },
}))

export const NoProductsHeld = ({ member }) => {
    const { t } = useTranslation();

    return <Container>
        <img src="/assets/images/illustrations/products-held.svg" alt="no-products-held" />
        <Text fontWeight={600} >
            {member.firstName} {t('DoesntHaveAnyProductsYet')}
        </Text>
    </Container>
}

