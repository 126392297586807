import {
    GET_SMES_BY_SITE_ID,
} from '../actions/SmeActions'

const initialState = {
    smeList: [],
}

const SmeReducer = function (state = initialState, action) {
    switch (action.type) {
    case GET_SMES_BY_SITE_ID: {
        return {
            ...state,
            ...action.payload,
        }
    }
    default: {
        return {
            ...state,
        }
    }
    }
}

export default SmeReducer
