import { components } from './themeComponents'
import { createTheme } from '@mui/material/styles';
import { convertPxToRem } from 'app/utils/utils'

const theme = createTheme();

export const themeConfig = {
    typography: {
        fontSize: 16,
        H1: {
            fontSize: convertPxToRem(48),
            [theme.breakpoints.down('md')]: {
                fontSize: convertPxToRem(42),
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: convertPxToRem(36),
            },
            fontWeight: 700,
            lineHeight: 1.2,
        },
        H2: {
            fontSize: convertPxToRem(40),
            [theme.breakpoints.down('md')]: {
                fontSize: convertPxToRem(36),
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: convertPxToRem(32),
            },
            fontWeight: 700,
            lineHeight:1.2,
            marginBlock:  '10px',
        },
        H3: {
            fontSize: convertPxToRem(32),
            [theme.breakpoints.down('md')]: {
                fontSize: convertPxToRem(26),
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: convertPxToRem(22),
            },
            fontWeight: 700,
            lineHeight: 1,
        },
        H4: {
            fontSize: convertPxToRem(24),
            [theme.breakpoints.down('md')]: {
                fontSize: convertPxToRem(22),
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: convertPxToRem(20),
            },
            fontWeight: 600,
            lineHeight: 1,
            marginBlock:  '5px',
        },
        H5: {
            fontSize: convertPxToRem(18),
            [theme.breakpoints.down('md')]: {
                fontSize: convertPxToRem(16),
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: convertPxToRem(15),
            },
            fontWeight: 600,
            lineHeight: 1.5,
        },
        L1: {
            fontSize: convertPxToRem(48),
            [theme.breakpoints.down('md')]: {
                fontSize: convertPxToRem(42),
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: convertPxToRem(36),
            },
            fontWeight: 400,
            lineHeight: 1.2,
        },
        L2: {
            fontSize: convertPxToRem(40),
            [theme.breakpoints.down('md')]: {
                fontSize: convertPxToRem(36),
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: convertPxToRem(32),
            },
            fontWeight: 400,
            lineHeight: 1.2,
        },
        L3: {
            fontSize: convertPxToRem(32),
            [theme.breakpoints.down('md')]: {
                fontSize: convertPxToRem(26),
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: convertPxToRem(22),
            },
            fontWeight: 400,
            lineHeight: 1.2,
        },
        L4: {
            fontSize: convertPxToRem(24),
            [theme.breakpoints.down('md')]: {
                fontSize: convertPxToRem(22),
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: convertPxToRem(20),
            },
            fontWeight: 400,
            lineHeight: 1.2,
        },
        L: {
            fontSize: convertPxToRem(20),
            [theme.breakpoints.down('md')]: {
                fontSize: convertPxToRem(18),
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: convertPxToRem(16),
            },
            fontWeight: 400,
            lineHeight: 1.5,
        },
        M: {
            fontSize: convertPxToRem(16),
            [theme.breakpoints.down('md')]: {
                fontSize: convertPxToRem(15),
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: convertPxToRem(14),
            },
            fontWeight: 400,
            lineHeight: 1.5,
        },
        S: {
            fontSize: convertPxToRem(13),
            [theme.breakpoints.down('md')]: {
                fontSize: convertPxToRem(12),
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: convertPxToRem(11),
            },
            fontWeight: 400,
            lineHeight: 1.5,
        },
    },
    components,
}
