import React, { lazy } from "react";
import Loadable from "app/components/Loadable/Loadable";

const ServerStatus = Loadable(lazy(() => import("./ServerStatus")));

const commonRoutes = [
    {
        path: "/status",
        element: <ServerStatus />,
    },
];

export default commonRoutes;
