import axios from 'axios.js'
import { get } from 'lodash'

export const GET_USERS_LIST = "GET_USERS_LIST";

export const getUsers = () => {
    return (dispatch) => {
        axios.get('/user/all').then(response => {
            const { users } = get(response, "data.data", []);
            dispatch({
                type: GET_USERS_LIST,
                payload: {
                    userList: users,
                },
            })
        })
    }
}

export const getUser = (userId, callback) => {
    return () => {
        axios.get(`/user/${userId}`).then(response => {
            const { user } = get(response, "data.data", []);
            callback?.(user)
        })
    }
}

