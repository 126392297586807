import { TagList } from 'app/components/TagsAndBudget'
import { selectTagList } from 'app/redux/selectors/tagSelector'
import React from 'react'
import { useSelector } from 'react-redux'



const TagsAndBudgetPage = () => {
    const tagList = useSelector(selectTagList)

    return <TagList tags={tagList} />
}

export default TagsAndBudgetPage