import React, { lazy } from "react";
import Loadable from "app/components/Loadable/Loadable";
import { authRoles } from "app/auth/authRoles";

const Dashboard = Loadable(lazy(() => import("./Dashboard")));
const ManageApps = Loadable(lazy(() => import("./ManageApps")));
const AiTools = Loadable(lazy(() => import("./AiTools")));

const myEquipmentsRoutes = [
    {
        path: "/dashboard",
        element: <Dashboard />,
        auth: [authRoles.all],
        level: 1,
    },
    {
        path: "/ai-text",
        element: <AiTools />,
        auth: [authRoles.all],
        level: 1,
    },
    {
        path: '/manage-apps',
        element: <ManageApps />,
        auth: [authRoles.all],
        level: 2,
    },
];

export default myEquipmentsRoutes;
