import { SET_LOADING } from '../actions/LoadingActions'

const initialState = false;

const LoadingReducer = function (state = initialState, action) {
    switch (action.type) {
    case SET_LOADING: {
        return action.loading;
    }
    default: {
        return state;
    }
    }
};

export default LoadingReducer;
