import React, { useEffect, useState } from 'react';
import { Box, Grid, styled, TablePagination } from '@mui/material';
import { TeamSearch, TeamMemberList } from 'app/components/TeamsAndApprovals';
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { getTags } from "app/redux/actions/TagActions";
import { getTeamMembers } from "app/redux/actions/TeamsActions";

const PageWrapper = styled(Box)(() => ({
    marginTop: 20,
    marginBottom: 100,
}))

const PaginationTeams = styled(TablePagination)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        '& .MuiTablePagination-displayedRows': {
            marginRight: 'auto',
        },
        '& .MuiTablePagination-spacer': {
            display: 'none',
        },
        '& .MuiTablePagination-selectLabel': {
            display: 'none',
        },
        '& .MuiInputBase-colorPrimary': {
            display: 'none',
        },
    },
}))

const TeamAndApprovalsPage = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading)
    const teamMembers = useSelector((state) => state.teams.teamMembers)
    const meta = useSelector((state) => state.teams.meta)

    const limit = 5; // Modify page size
    const [searchKey, setSearchKey] = useState("")
    const [members, setMembers] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(limit);

    useEffect(() => {
        getAllTags();
    }, [])

    useEffect(() => {
        getMembers()
        setPage(0);
    }, [searchKey, rowsPerPage])

    useEffect(() => {
        setMembers(teamMembers);
    }, [teamMembers])

    useEffect(() => {
        setCount(get(meta, 'total', 0));
    }, [meta])

    const getAllTags = () => {
        dispatch(getTags());
    }

    const getMembers = (cursor) => {
        dispatch(getTeamMembers({
            keyword: searchKey,
            limit: rowsPerPage,
            cursor: cursor,
        }));
    }

    const handleChangePage = (event, newPage) => {
        const { cursor, backCursor } = meta;
        let cursorId = ''
        if (newPage !== 0) {
            cursorId = page < newPage ? cursor : backCursor
        }
        setPage(newPage);
        getMembers(cursorId);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    return (
        <PageWrapper data-testid="team-and-approvals-page">
            <Grid container>
                <Grid item xs={12}>
                    <TeamSearch setSearchKey={setSearchKey} />
                </Grid>
                {(!loading && members) && (
                    <Grid item xs={12} data-testid="member-list" >
                        <TeamMemberList pageItems={members} count={count} />
                        <PaginationTeams
                            component="div"
                            rowsPerPageOptions={[5, 10, 25]}
                            count={count}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            showFirstButton={true}
                        />
                    </Grid>
                )}
            </Grid>
        </PageWrapper>
    )
}

export default TeamAndApprovalsPage;