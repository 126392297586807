import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const NotFound = Loadable(lazy(() => import("./NotFound")));
const ForgotPassword = Loadable(lazy(() => import("./forgotPassword/ForgotPassword")));
const ResetPassword = Loadable(lazy(() => import("./forgotPassword/ResetPassword")));
const JwtLogin = Loadable(lazy(() => import("./login/JwtLogin")));
const JwtRegister = Loadable(lazy(() => import("./register/JwtRegister")));
const VerifyEmail = Loadable(lazy(() => import("./verifyEmail/VerifyEmail")));
const VerifyEmailToken = Loadable(lazy(() => import("./verifyEmail/VerifyEmailToken")));
const VerifyEmailTokenExpire = Loadable(lazy(() => import("./verifyEmail/VerifyEmailTokenExpire")));

const sessionRoutes = [
    {
        path: '/signin',
        element: <JwtLogin />,
    },
    {
        path: '/signup',
        element: <JwtRegister />,
    },
    {
        path: '/signup/:urlPostfix',
        element: <JwtRegister />,
    },
    {
        path: '/verify-email',
        element: <VerifyEmail />,
    },
    {
        path: '/verify-email-token/:email/:token',
        element: <VerifyEmailToken />,
    },
    {
        path: '/email-verification-link-expired',
        element: <VerifyEmailTokenExpire />,
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword />,
    },
    {
        path: '/reset-password/:email/:token',
        element: <ResetPassword />,
    },
    {
        path: '/404',
        element: <NotFound />,
    },
]

export default sessionRoutes
