import React from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { techpathTheme } from '.'
import { SnackbarProvider } from 'notistack'

export const TechpathTheme = ({ children }) => {
    return (
        <ThemeProvider theme={techpathTheme}>
            <CssBaseline />
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                preventDuplicate={true}
            >                
                {children}
            </SnackbarProvider>
        </ThemeProvider>
    )
}
