import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, Autocomplete, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, useMediaQuery, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getTagsSortedByIsDefault } from 'app/utils/utils'
import { LoadingButton } from '../button/Button';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/styles';
import Tag from '../Tag/Tag';
import { UpdateUserTags } from "app/redux/actions/UserTagActions";
import { selectTagList } from 'app/redux/selectors/tagSelector';
import { getTags } from "app/redux/actions/TagActions";

const UpdateTags = ({ open, handleEnableUpdateTags, memberId, userTags, setUserTags }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const tagList = useSelector(selectTagList)
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [tagSaving, setTagSaving] = useState(false);
    const [updatedTags, setUpdatedTags] = useState(userTags || []);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTags());
    }, [])

    const handleTagUpdate = async () => {
        setTagSaving(true)
        await dispatch(UpdateUserTags(memberId, {
            tags: updatedTags.map((tag) => tag?.id),
        }));
        setUserTags(updatedTags)
        handleEnableUpdateTags();
    }

    const handleChangeAutoSelect = (value) => {
        setUpdatedTags(value);
    }

    return <Dialog fullScreen={fullScreen} open={open} fullWidth>
        <DialogTitle id="responsive-dialog-title" sx={{ width: '100%' }}>
            {t('UpdateTags')}
        </DialogTitle>
        <DialogContent sx={{ width: '100%' }}>
            <IconButton
                aria-label="close"
                onClick={() => handleEnableUpdateTags()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContentText>
                <Autocomplete
                    multiple
                    id="tags-outlined"
                    name="tags-outlined"
                    options={tagList}
                    getOptionLabel={(option) => option?.name}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    onChange={(id, value) => handleChangeAutoSelect(value)}
                    value={getTagsSortedByIsDefault(updatedTags)}
                    disableCloseOnSelect={true}
                    filterSelectedOptions
                    disableClearable={true}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Tag
                                key={index}
                                label={option?.name}
                                {...getTagProps({ index })}
                                disabled={option?.isDefault}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            required={true}
                            fullWidth
                            onKeyDown={(event) => {
                                if (event.key === 'Backspace') {
                                    event.stopPropagation();
                                }
                            }}
                        />
                    )}
                />
            </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
            <LoadingButton loading={tagSaving} onClick={handleTagUpdate} >{t('Save')}</LoadingButton>
        </DialogActions>
    </Dialog>
}

export default UpdateTags