import styled from '@emotion/styled'
import { Box, Stack } from '@mui/material'
import React from 'react'
import { Heading, Text } from '../Typography'

const Card = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.black.darker16}`,
    borderRadius: theme.spacing(1),
    padding: 30,
}))


export const BudgetOverview = () => {
    return <Card>
        <Heading size='H4'>Budget overview</Heading>
        <Stack sx={{ marginTop: 5 }} rowGap={3}>
            <div>
                <Heading size='H3'>£10,034</Heading>
                <Text>Total spent year to date</Text>
            </div>
            <div>
                <Heading size='H3'>£4,340</Heading>
                <Text>Total spent per month</Text>
            </div>
        </Stack>
    </Card>
}
