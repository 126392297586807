import React from 'react'
import AuthGuard, { PreAuthGuard } from 'app/auth/AuthGuard'
import NotFound from 'app/views/sessions/NotFound'
import Dashboard from 'app/views/sessions/Dashboard'
import myEquipmentsRoutes from 'app/views/my-equipments/MyEquipmentsRoutes'
import appManageRoutes from 'app/views/app-manage/AppManageRoutes'
import fundingRoutes from 'app/views/funding/FundingRoutes'
import sessionRoutes from 'app/views/sessions/SessionRoutes'
import settingsRoutes from 'app/views/settings/SettingsRoutes'
import commonRoutes from 'app/views/common/CommonRoutes'
import MatxLayout from '../components/MatxLayout/MatxLayout'
import teamAndApprovalsRoutes from 'app/views/team-amd-approvals/TeamAndApprovalsRoutes'
import tagsAndBudgetRoutes from 'app/views/tags-and-budget/TagsAndBudgetRoutes'
import approvedProductsRoutes from 'app/views/approved-products/ApprovedProductsRoutes'
import fundersDashboardRoutes from 'app/views/funders-dashboard/FundersDashboardRoutes'
import productRoutes from 'app/views/products/ProductRoutes'
import aiAdsRoutes from 'app/views/ai-ads/AiAdsRoutes'

export const AllPages = () => {
    const all_routes = [
        {
            element: (
                <AuthGuard>
                    <MatxLayout />
                </AuthGuard>
            ),
            children: [
                ...settingsRoutes,
                ...appManageRoutes,
                ...fundingRoutes,
                ...aiAdsRoutes,
                ...productRoutes,
                ...myEquipmentsRoutes,
                ...teamAndApprovalsRoutes,
                ...tagsAndBudgetRoutes,
                ...approvedProductsRoutes,
                ...fundersDashboardRoutes,
            ],
        },
        {
            element: (
                <PreAuthGuard>
                    <MatxLayout />
                </PreAuthGuard>
            ),
            children: [...sessionRoutes],
        },
        {
            element: (
                <MatxLayout />
            ),
            children: [...commonRoutes],
        },
        {
            path: '/',
            element: <Dashboard />,
        },
        {
            path: '*',
            element: <NotFound />,
        },
    ]

    return all_routes
}
