import { Container, Stack, styled } from '@mui/material';
import MainHeader from 'app/components/MainHeader/MainHeader';
import { Heading } from 'app/components/Typography';
import React from 'react';
// import Button from 'app/components/button/Button';
import TeamAndApprovalsPage from 'app/views/team-amd-approvals/TeamAndApprovalsPage';
import { useTranslation } from 'react-i18next';
import useAuth from 'app/hooks/useAuth';

const PageContainer = styled(Container)(() => ({
    maxWidth: "1440px !important",
}))

const TeamAndApprovals = () => {
    const { t } = useTranslation();

    const { user } = useAuth()

    if (!user) return null

    return <>
        <MainHeader>
            <MainHeader.LeftContent>
                <Heading>
                    {t('Team&Approvals')}
                </Heading>
            </MainHeader.LeftContent>
            <MainHeader.RightContent>
                <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 1 }} >
                    {/* <Button variant="outlined" color="black" sx={{ width: { xs: '100%', md: 'auto' } }} >{t('AddTeamMember')}</Button> */}
                    {/* TODO: POST MVP */}
                    {/* <Button variant="contained" color="primary" sx={{ width: { xs: '100%', md: 'auto' } }} >Import team from HR platform</Button> */}
                </Stack>
            </MainHeader.RightContent>
        </MainHeader>
        <PageContainer>
            <TeamAndApprovalsPage />
        </PageContainer>
    </>
}

export default TeamAndApprovals