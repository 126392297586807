import { Box, Stack, styled } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { formatCurrencyString, useShoppingCart } from 'use-shopping-cart'
import { Text } from 'app/components/Typography';
import { formatDistance, format } from 'date-fns'
import Button from 'app/components/button/Button';
import GenericDialog from 'app/components/Dialog/GenericDialog'
import { defaultCurrency, productStatus } from 'app/utils/constant'
import { useDispatch, useSelector } from 'react-redux'
import { setCheckoutInprogressStatus, checkoutCart } from "app/redux/actions/CartActions";
import { updateUserProductStatus } from "app/redux/actions/UserProductActions";
import { useTranslation } from 'react-i18next';
import { isAdmin } from 'app/utils/utils'

const ProductCardContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingBlock: theme.spacing(2),
    gap: theme.spacing(2),
    '.card-left': {
        display: 'flex',
        justifyContent: 'space-between',
        gap: theme.spacing(1.5),
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row-reverse',
            justifyContent: 'flex-start',
            alignItems: "center",
            columnGap: theme.spacing(2),
        },
    },
    '.card-right': {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            alignItems: "center",
            justifyContent: 'flex-end',
            columnGap: theme.spacing(1),
        },
    },
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',

    },
}))

const ProductDescription = styled(Box)(({ theme }) => ({
    '.price': {
        paddingBlock: theme.spacing(0.5),
    },
}))

const ProductImage = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.black.darker16}`,
    borderRadius: theme.spacing(1),
    padding: '4px',
    width: '70px',
    height: '70px',
    flex: '0 0 auto',
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
    [theme.breakpoints.up('sm')]: {
        width: '90px',
        height: '90px',
    },
}))

const ProductActions = styled(Box)(() => ({
    '.product-action-btn': {
        width: '100%',
    },
}))



const CheckoutButton = styled(Button)(() => ({
    background: '#F37934',
    color: '#FFFFFF',
    border: '1px solid #F37934',
    '&:hover': {
        background: '#E6661E',
        color: '#FFFFFF',
        border: '1px solid #E6661E',
    },
    '&.Mui-disabled': {
        background: 'rgba(0, 0, 0, 0.12)',
        color: 'rgba(0, 0, 0, 0.26)',
    },
}))

const getProductStatus = (product, t) => {
    const { approval_status: status, requested_at: dateRequested } = product
    if (status === productStatus.pending) {
        return <Stack direction="row" alignItems="center" spacing={2}>
            <Text fontWeight={600}>{t('Requested')} {formatDistance(new Date(dateRequested), Date.now(), { addSuffix: true })}</Text>
        </Stack>
    }
    if (status === productStatus.approved) {
        return <Text fontWeight={600}>{t('Delivered')} {format(product.deliveryDate, 'do MMMM')}</Text> // TODO: deliveryDate
    }
    if (status === productStatus.declined) {
        return <Text fontWeight={600}>{t('Declined')}</Text>
    }
    if (status === productStatus.processing || status === productStatus.held) { // TODO: need to review with shipping info
        return <Text fontWeight={600}>{t('Processing')}</Text>
    }
    if (status === productStatus.incorrectPayment) {
        return <Text fontWeight={600} color="#F37934">{t('IncorrectPayment')}</Text>
    }
}

export const ProductCard = ({ product: productData }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isCheckoutInprogress = useSelector((state) => state.cart.isCheckoutInprogress)
    const { addItem, removeItem, cartDetails } = useShoppingCart()
    const {
        product: { product_id: productId, image, name, price, currency_iso: currency, rental_period: rentalPeriod },
        user_id: userId,
        requested_at: requestedAt,
        approval_status: status,
    } = productData;
    const customId = `${productId}_${userId}_${requestedAt}`;

    const [isInTheCart, setIsInTheCart] = useState(false);
    const [isDeclining, setIsDeclining] = useState(false);

    useEffect(() => {
        if (customId) {
            setIsInTheCart(!!cartDetails[customId])
        }
    }, [cartDetails, customId])

    const handleAddToCart = () => {
        addItem({
            ...productData.product,
            id: customId,
            productId: productId,
            requestedAt: requestedAt,
            price: price,
            currency: currency || defaultCurrency,
            userId: userId,
        })
    }

    const handleCheckout = () => {
        dispatch(setCheckoutInprogressStatus(true));
        dispatch(checkoutCart(cartDetails));
    }

    const handleDecline = () => {
        dispatch(updateUserProductStatus({
            approveeUserId: userId,
            requestedAt: requestedAt,
            status: productStatus.declined,
        }, productId, customId));
        setIsDeclining(false);
    }

    const handleReload = () => {
        window.location.reload()
    }

    return <>
        <GenericDialog
            open={isDeclining}
            handleClose={() => setIsDeclining(false)}
            handleAccept={() => handleDecline()}
            Message={t('AreYouSureYouWantToDeclineThis')}
        />
        <ProductCardContainer>
            <div className='card-left' data-testid="product-card">
                <ProductDescription>
                    <Text className="description" data-testid="product-name">{name}</Text>
                    <Text className="price" data-testid="rental" fontWeight={600}>{`${formatCurrencyString({ value: price, currency: currency || defaultCurrency })} ${rentalPeriod ? `/mo. ${rentalPeriod} months rental` : ''}`}</Text>
                    {getProductStatus(productData, t)}
                </ProductDescription>
                <ProductImage>
                    <img src={image} alt={name} />
                </ProductImage>
            </div>
            <div className='card-right'>
                <ProductActions>
                    {(status === productStatus.processing) && (
                        <Stack direction={"row"} spacing={1}>
                            <Button
                                data-testid="product-card-refresh-button"
                                className="product-action-btn"
                                variant="contained"
                                size="small"
                                color="success"
                                disabled={isCheckoutInprogress}
                                onClick={() => handleReload()}
                            >
                                {t('Refresh')}
                            </Button>
                        </Stack>
                    )}
                    {(status === productStatus.pending && isAdmin()) && (
                        !isInTheCart ? (
                            <Stack direction={"row"} spacing={1}>
                                <Button
                                    data-testid="product-card-decline-button"
                                    className="product-action-btn"
                                    variant="outlined"
                                    size="small"
                                    color="black"
                                    disabled={isCheckoutInprogress}
                                    onClick={() => setIsDeclining(true)}
                                >
                                    {t('Decline')}
                                </Button>
                                <Button
                                    data-testid="product-card-approve-button"
                                    className="product-action-btn"
                                    variant="contained"
                                    size="small"
                                    color="success"
                                    disabled={isCheckoutInprogress}
                                    onClick={() => handleAddToCart()}
                                >
                                    {t('Approve')}
                                </Button>
                            </Stack>
                        ) : (
                            <Stack direction={"row"} spacing={1}>
                                <Button
                                    data-testid="product-card-remove-button"
                                    className="product-action-btn"
                                    variant="outlined"
                                    size="small"
                                    color="black"
                                    disabled={isCheckoutInprogress}
                                    onClick={() => removeItem(customId)}
                                >
                                    {t('Remove')}
                                </Button>
                                <CheckoutButton
                                    data-testid="product-card-checkout-button"
                                    className="product-action-btn"
                                    variant="outlined"
                                    size="small"
                                    disabled={isCheckoutInprogress}
                                    onClick={() => handleCheckout()}
                                >
                                    {t('Checkout')}
                                </CheckoutButton>
                            </Stack>
                        )
                    )}
                </ProductActions>
            </div>
        </ProductCardContainer>
    </>
}