/* eslint-disable indent */
import { styled } from '@mui/material';
import Chip from '@mui/material/Chip';
import React from 'react';
import PropTypes from "prop-types";

const StyledTag = styled(Chip)(({ theme }) => ({
    background: theme.palette.tag.default,
    color: theme.palette.black.main,
    fontSize: '0.8125rem',
    height: 35,
    margin: '4px 8px 4px 0 !important',
}))

const StyledStatusTag = styled(Chip)(() => ({
    fontWeight: 500,
    fontSize: '0.75rem',
}))


const Tag = (props) => {
    return <StyledTag {...props} />
}

export const StatusTag = (props) => {
    return <StyledStatusTag {...props} size="small" />
}

Tag.prototypes = {
    label: PropTypes.string,
    theme: PropTypes.object,
}

export default Tag

