import axios from 'axios.js'
import { get } from 'lodash'
import { setMessage } from "app/redux/actions/MessageActions";

export const ADD_USER_TAGS = 'ADD_USER_TAGS'
export const UPDATE_USER_TAGS = 'UPDATE_USER_TAGS'
export const DELETE_USER_TAGS = 'DELETE_USER_TAGS'

export const AddUserTags = (userId, data) => {
    return (dispatch) => {
        axios.post(`/user/${userId}/tags`, data).then(response => {
            const { tags } = get(response, "data.data");
            dispatch(setMessage({
                message: get(response, 'data.message', ''),
                variant: "success",
            }));

            dispatch({
                type: ADD_USER_TAGS,
                payload: {
                    tagList: tags,
                },
            })

            return response
        }).catch((error) => {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        });
    }
}

export const UpdateUserTags = (userId, data) => {
    return (dispatch) => {
        axios.put(`/user/${userId}/tags`, data).then(response => {
            const { tags } = get(response, "data.data");
            dispatch(setMessage({
                message: get(response, 'data.message', ''),
                variant: "success",
            }));

            dispatch({
                type: UPDATE_USER_TAGS,
                payload: {
                    tagList: tags,
                },
            })

            return response
        }).catch((error) => {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        });
    }
}

export const DeleteUserTags = (userId, data) => {
    return (dispatch) => {
        axios.delete(`/user/${userId}/tag`, { data }).then(response => {
            const { tags } = get(response, "data.data");
            dispatch(setMessage({
                message: get(response, 'data.message', ''),
                variant: "success",
            }));
            dispatch({
                type: DELETE_USER_TAGS,
                payload: {
                    tagList: tags,
                },
            })
        })
    }
}