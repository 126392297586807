import {
    CREATE_TAG,
    DELETE_TAG,
    GET_TAGS_LIST,
    UPDATE_TAG,
} from '../actions/TagActions'

const initialState = {
    tagList: [],
}

const TagReducer = function (state = initialState, action) {
    switch (action.type) {
    case GET_TAGS_LIST: {
        return {
            ...state,
            tagList: [...action.payload],
        }
    }
    case CREATE_TAG: {
        return {
            ...state,
            tagList: [
                ...state.tagList,
                action.payload,
            ],
        }
    }
    case UPDATE_TAG: {
        return {
            ...state,
            tagList: state.tagList.map(tag => {
                if(tag.id === action.payload.id) {
                    return { ...action.payload }
                }
                return tag
            }),
        }
    }
    case DELETE_TAG: {
        return { 
            ...state,
            tagList: state.tagList.filter(tag => tag.id !== action.payload.tagId),
        }
    }
    default: {
        return {
            ...state,
        }
    }
    }
}

export default TagReducer
