import {
    ADD_USER_TAGS,
    UPDATE_USER_TAGS,
    DELETE_USER_TAGS,
} from '../actions/UserTagActions'

const initialState = {
    tagList: [],
}

const UserTagReducer = function (state = initialState, action) {
    switch (action.type) {
    case ADD_USER_TAGS: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case UPDATE_USER_TAGS: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case DELETE_USER_TAGS: {
        return {
            ...state,
            ...action.payload,
        }
    }
    default: {
        return {
            ...state,
        }
    }
    }
}

export default UserTagReducer
