import { Box, styled, Divider, Stack } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { TeamMemberCard } from 'app/components/TeamsAndApprovals/TeamMemberCardNew'
import { Text } from 'app/components/Typography'
import { useTranslation } from 'react-i18next';

const ListContainer = styled(Stack)(() => ({
    marginTop: 50,
    gap: 0,
}))

export const TeamMemberList = ({ pageItems, count }) => {
    const { t } = useTranslation();
    const [teamMembers, setTeamMembers] = useState(pageItems || [])

    useEffect(() => {
        setTeamMembers(pageItems)
    }, [pageItems])

    return <ListContainer direction="column" gap={2}>
        <Text marginBottom={3}>
            {count} {t('TeamMembers')}
        </Text>
        {
            teamMembers && teamMembers.map(member => {
                return <Box key={member.id}>
                    <TeamMemberCard member={member} />
                    <Divider />
                </Box>
            })
        }
    </ListContainer>
}

