import { styled } from '@mui/material'
import React from 'react'
import { default as MuiButton } from '@mui/material/Button';
import { default as MuiLoadingButton } from '@mui/lab/LoadingButton';

const StyledButton = styled(MuiButton)(({ size }) => {
    if (size === "small") {
        return {
            fontSize: '0.8125rem',
            padding: '5px 10px',
        }
    }
})

const StyledLoadingButton = styled(MuiLoadingButton)(({ size }) => {

    if (size === "small") {
        return {
            fontSize: '0.8125rem',
            padding: '5px 10px',
        }
    }

})

const Button = (props) => <StyledButton {...props}></StyledButton>

export const LoadingButton = (props) => <StyledLoadingButton {...props}></StyledLoadingButton>

export default Button