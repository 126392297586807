import useAuth from 'app/hooks/useAuth';
import { useDidMountEffect } from 'app/hooks/useDidMountEffect';
import { getTags } from 'app/redux/actions/TagActions';
import { useSnackbar } from 'notistack';
import React  from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export const AppContext = React.createContext({})

export const AppProvider = ({ children }) => {
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const notifyStatus = useSelector((state) => state.common.notifyStatus)
    const {user} = useAuth()

    useDidMountEffect(() => {
        if(user && user.userId) fetchAllTags()
    }, [user])

    const fetchAllTags =  () => {
        dispatch(getTags())
    }

    useDidMountEffect(() => {
        enqueueSnackbar(t(notifyStatus.message), { variant: notifyStatus.variant })
    }, [notifyStatus])

    return <AppContext.Provider value={null}>{children}</AppContext.Provider>
}


