import { Box, Stack, styled } from '@mui/material'
import React from 'react'
// import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import Tag from '../Tag/Tag';
import Divider from '@mui/material/Divider';
import { Heading, Text } from '../Typography';
import { get, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next';

const EquipmentOverviewContainer = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.black.darker16}`,
    borderRadius: theme.spacing(1),
    padding: 30,
}))

const InfoList = styled(Stack)(() => ({
    'div': {
        display: "flex",
        alignItems: 'Center',
        gap: 15,
        paddingTop: 4,
        paddingBottom: 4,
    },
}))

export const MemberEquipmentOverview = ({ member }) => {
    const { t } = useTranslation();
    const { products, tags = [] } = member;

    return <EquipmentOverviewContainer>
        <Box marginBottom={3}>
            <Heading size='H4' marginBottom={2}>{t('Overview')}</Heading>
            <InfoList>
                <Box>
                    <AssignmentTurnedInOutlinedIcon />
                    <Text>{get(products, "held", 0)} {t('ProductsHeld')}</Text>
                </Box>
                {/* TODO: Post MVP */}
                {/* <Box>
                    <SavingsOutlinedIcon />
                    <Text>Budget used £45 of £60 /mo.</Text>
                </Box> */}
            </InfoList>
        </Box>
        <Box marginBottom={3}>
            <Text marginBottom={2} fontWeight={600} >
                {t('Tagged')}
            </Text>
            <Stack direction="row" spacing={1} flexWrap="wrap" rowGap={3} >
                {tags.map(tag => <Tag key={tag.id} label={tag.name} />)}
                {isEmpty(tags) && (
                    t('NoTagsAdded')
                )}
            </Stack>
        </Box>
        <Divider />
        {/* TODO: Post MVP */}
        {/* <Stack spacing={1} paddingTop={2}>
            <Text><a>Edit Profile</a></Text>
            <Text><a>Send invite email</a></Text>
            <Text><a>Offboard team member</a></Text>
        </Stack> */}
    </EquipmentOverviewContainer>
}

