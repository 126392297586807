import { GET_SME_PRODUCT_LIST, UPDATE_SME_PRODUCT_LIST, GET_USER_PRODUCT_LIST, SME_PRODUCTS_FOR_REQUEST_PAGE } from "../actions/ProductAction"

const initialState = {
    smeProductList: {},
    userProductList: {},
    requestProductList: {},
}

const ProductReducer = function (state = initialState, action) {
    switch (action.type) {
    case GET_SME_PRODUCT_LIST: {
        return {
            ...state,
            smeProductList: action.payload,
        }
    }
    case UPDATE_SME_PRODUCT_LIST: {
        return {
            ...state,
            smeProductList: {
                ...state.smeProductList,
                [action.payload.type]: state.smeProductList[action.payload.type].map(product => {
                    if (product.product_id === action.payload.product_id) {
                        return { ...product, tags: action.payload.selectedTags }
                    }
                    return product
                }),
            },
        }
    }
    case GET_USER_PRODUCT_LIST: {
        return {
            ...state,
            userProductList: action.payload,
        }
    }
    case SME_PRODUCTS_FOR_REQUEST_PAGE: {
        return {
            ...state,
            requestProductList: action.payload,
        }
    }
    default: {
        return {
            ...state,
        }
    }
    }
}

export default ProductReducer