
import React from 'react'
import { TagCard } from './TagCard'
import { Box, Divider } from '@mui/material'

export const TagList = ({ tags }) => {

    if (!tags || tags.length === 0) {
        return null
    }

    return <Box>
        {
            tags.map(tag => {
                return <div key={tag.id}>
                    <TagCard tag={tag} />
                    <Divider />
                </div>
            })
        }
    </Box>

}

