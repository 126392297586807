import React, { lazy } from "react";
import Loadable from "app/components/Loadable/Loadable";
import { authRoles } from "app/auth/authRoles";

const Products = Loadable(lazy(() => import("./products/Products")));
const Subscription = Loadable(lazy(() => import("./Subscription/Subscription")));

const productRoutes = [
    {
        path: "/products",
        element: <Products />,
        auth: [authRoles.all],
        level: 1,
    },
    {
        path: "/products/:status",
        element: <Products />,
        auth: [authRoles.all],
        level: 1,
        isCheckout: true,
    },
    {
        path: "/subscription",
        element: <Subscription />,
        auth: [authRoles.all],
        level: 1,
    },
    {
        path: "/subscription/:status",
        element: <Subscription />,
        auth: [authRoles.all],
        level: 1,
        isCheckout: true,
    },
];

export default productRoutes;
