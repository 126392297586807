import Mock from '../mock'
import jwt from 'jsonwebtoken'

const JWT_SECRET = 'jwt_secret_key'
const JWT_VALIDITY = '7 days'

const userList = [
    {
        id: 1,
        role: 'SA',
        name: 'Jason Alexander',
        username: 'jason_alexander',
        email: 'jason@newpath.com',
        avatar: '/assets/images/face-6.jpg',
        age: 25,
        organisationSetupCompleted: true,
    },
    {
        id: 2,
        role: 'SA',
        name: 'John Doe',
        username: 'john_doe',
        email: 'john@newpath.com',
        avatar: '/assets/images/face-6.jpg',
        age: 25,
        organisationSetupCompleted: true,
    },
]

const organisationList = [
    {
        id: 1,
        name: 'NewPath',
    },
]

const hrPlatforms = ["BrightHR","GustoHR"];

// FOLLOWING CODES ARE MOCK SERVER IMPLEMENTATION
// YOU NEED TO BUILD YOUR OWN SERVER
// IF YOU NEED HELP ABOUT SERVER SIDE IMPLEMENTATION
// CONTACT US AT support@ui-lib.com

Mock.onPost('/api/auth/login').reply(async (config) => {
    try {
        await new Promise((resolve) => setTimeout(resolve, 1000))

        const { email } = JSON.parse(config.data)
        const user = userList.find((u) => u.email === email)

        if (!user) {
            return [400, {
                messageCode: 'InvalidEmailPassword',
                message: 'Invalid email or password',
            }]
        }
        const accessToken = jwt.sign({ userId: user.id }, JWT_SECRET, {
            expiresIn: JWT_VALIDITY,
        })

        return [
            200,
            {
                accessToken,
                user: {
                    id: user.id,
                    avatar: user.avatar,
                    email: user.email,
                    name: user.name,
                    role: user.role,
                    organisationSetupCompleted: user.organisationSetupCompleted,
                },
            },
        ]
    } catch (err) {
        console.error(err)
        return [500, { message: 'Internal server error' }]
    }
})

Mock.onPost('/api/auth/register').reply((config) => {
    try {
        const { email } = JSON.parse(config.data)
        const user = userList.find((u) => u.email === email)

        if (user) {
            return [400, {
                messageCode: 'UserAlreadyExists',
                message: 'User already exists!',
            }]
        }
        //OnlyOrgEmailsAreAllowed

        // const newUser = {
        //     id: 2,
        //     role: 'GUEST',
        //     name: '',
        //     username: username,
        //     email: email,
        //     avatar: '/assets/images/face-6.jpg',
        //     age: 25,
        //     organisationSetupCompleted: false,
        // }
        // userList.push(newUser)

        // const accessToken = jwt.sign({ userId: newUser.id }, JWT_SECRET, {
        //     expiresIn: JWT_VALIDITY,
        // })

        return [200, {
            messageCode: 'InvalidEmailPassword',
            message: 'Invalid email or password',
        },
            // {
            //     accessToken,
            //     user: {
            //         id: newUser.id,
            //         avatar: newUser.avatar,
            //         email: newUser.email,
            //         name: newUser.name,
            //         username: newUser.username,
            //         role: newUser.role,
            //         organisationSetupCompleted: newUser.organisationSetupCompleted,
            //     },
            // },
        ]
    } catch (err) {
        console.error(err)
        return [500, { message: 'Internal server error' }]
    }
})

Mock.onPost('/api/setDefaultSettings').reply((config) => {
    try {
        const { orgName } = JSON.parse(config.data)
        const organisation = organisationList.find((org) => org.name === orgName)

        if (organisation) {
            return [400, { message: 'Organisation already exists!' }]
        }

        return [
            200,
            {
                user: userList[0],
            },
        ]
    } catch (err) {
        console.error(err)
        return [500, { message: 'Internal server error' }]
    }
})

Mock.onGet('/api/hr-platforms').reply((config) => {
    try {
        console.log('=======config==========', config)
        return [200, { hrPlatforms}]
    } catch (err) {
        console.error(err)
        return [500, { message: 'Internal server error' }]
    }
})

Mock.onPost('/api/image-upload').reply((config) => {
    try {
        const { imageData } = JSON.parse(config.data)
        console.log('=======imageData-upload==========', imageData) // TODO: Remove
        return [200, { image: 'http://www.venmond.com/demo/vendroid/img/avatar/big.jpg' }]
    } catch (err) {
        console.error(err)
        return [500, { message: 'Internal server error' }]
    }
})

Mock.onGet('/api/auth/profile').reply(() => {
    try {
        // const { Authorization } = config.headers
        // if (!Authorization) {
        //     return [401, { message: 'Invalid Authorization token' }]
        // }

        // const accessToken = Authorization.split(' ')[1]
        // const { userId } = jwt.verify(accessToken, JWT_SECRET)
        // const user = userList.find((u) => u.id === userId)

        // if (!user) {
        //     return [401, { message: 'Invalid authorization token' }]
        // }
        
        const user = userList[0];

        return [
            200,
            {
                user: {
                    id: user.id,
                    avatar: user.avatar,
                    email: user.email,
                    name: user.name,
                    role: user.role,
                    organisationSetupCompleted: user.organisationSetupCompleted,
                },
            },
        ]
    } catch (err) {
        console.error(err)
        return [500, { message: 'Internal server error' }]
    }
})

Mock.onPost('/api/profile/update').reply(() => {
    try {
        const user = userList[0];

        return [
            200,
            {
                data: {
                    user: {
                        id: user.id,
                        avatar: user.avatar,
                        email: user.email,
                        name: user.name,
                        role: user.role,
                        organisationSetupCompleted: user.organisationSetupCompleted,
                    },
                    message: 'UserFetchedSuccessfully',
                },
            },
        ]
    } catch (err) {
        console.error(err)
        return [500, { message: 'Internal server error' }]
    }
})
