import axios from 'axios';
import { get } from 'lodash';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const makeBackendUrl = () => {
    const domainParts = window.location.hostname.split('.');
    const host = domainParts.slice(-2).join('.');
    const subDomainFront = domainParts.slice(0, -2).join('.');
    const subDomainBack = subDomainFront === 'app' ? 'api' : 'devapi';

    return `${window.location.protocol}//${subDomainBack}.${host}/v1/`;
}

const axiosInstance = axios.create();

// axiosInstance.defaults.baseURL = process.env.REACT_APP_API_END_POINT;
axiosInstance.defaults.baseURL = makeBackendUrl();
axiosInstance.defaults.timeout = 60000;

const refreshAuthLogic = (failedRequest) => {
    const accessToken = get(failedRequest, 'response.data.data.accessToken', null);

    if (accessToken) {
        failedRequest.response.config.headers['Authorization'] = accessToken;
        axiosInstance.defaults.headers.common.Authorization = accessToken;
        localStorage.setItem('accessToken', accessToken);
        return Promise.resolve();
    }

    return Promise.reject();
}

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {
    statusCodes: [401],
    retryInstance: axiosInstance,
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (get(error, 'response.status', 400) === 403) {
            localStorage.removeItem('accessToken');
            setTimeout(() => {
                window.location.reload();
            }, 1500)
        }
        return Promise.reject(
            (error?.response && error?.response?.data) || { message: 'Something went wrong!' },
        )
    },
)

export default axiosInstance
