
import styled from '@emotion/styled'
import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import Button from '../button/Button'
import Tag from '../Tag/Tag'
import { TagsDialog } from './TagsDialog'
import { useTranslation } from 'react-i18next';

const StyledCard = styled(Box)(({ theme }) => ({
    padding: `${theme.spacing(3)} 0`,
}))

export const TagCard = ({ tag }) => {
    const { t } = useTranslation();
    const [opened, setOpened] = useState(false);

    const handleEditTagClose = () => {
        setOpened(false)
    }

    return <StyledCard>
        {/* old taginfo */}
        {/* <Grid container columnSpacing={2}>
            <Grid item xs={10}>
                <Tag label={tagInfo.name} sx={{ marginBottom: 2 }} />
                {tagInfo.memberCount ? <Text>{tagInfo.memberCount} team members</Text> : null}
                {tagInfo.approvedProductsCount ? <Text>{tagInfo.approvedProductsCount} approved products</Text> : null}
                <Stack direction={{ xs: 'column', sm: 'row' }} columnGap={1} divider={<Divider orientation="vertical" flexItem />}>
                    <Text>{tagInfo.budget ? `Budget £${tagInfo.budget} /mo. per team member` : 'No budget set'}</Text>
                    {tagInfo.monthlySpent ? <Text>£{tagInfo.monthlySpent} spent /mo.</Text> : null}
                    {tagInfo.yearlySpent ? <Text>£{tagInfo.monthlySpent} spent year to date</Text> : null}
                </Stack>
            </Grid>
            <Grid item xs={2}>
                <Box textAlign="right">
                    <Button variant="outlined" size="small" color="black" onClick={() => setEditMode(!editMode)}>Edit</Button>
                </Box>
            </Grid>
            <EditTag editMode={editMode} handleEditTagClose={handleEditTagClose} />
        </Grid> */}
        <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={9}>
                <Tag label={tag.name} />
            </Grid>
            <Grid item xs={3} textAlign="right">
                {!tag.isDefault && <Button variant="outlined" size="small" color="black" onClick={() => setOpened(!opened)}>{t('Edit')}</Button>}
            </Grid>
            <TagsDialog opened={opened} mode="EDIT" handleTagClose={handleEditTagClose} tag={tag} />
        </Grid>
    </StyledCard>
}

