export const topBarHeight = 64
export const sideNavWidth = 260
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200
export const defaultCurrency = 'USD'
export const allowedCountries=['US', 'GB', 'CA']
export const productStatus = {
    pending: 'PENDING',
    approved: 'APPROVED',
    declined: 'DECLINED',
    held: 'HELD',
    processing: 'PROCESSING',
    incorrectPayment: 'INCORRECT_PAYMENT',
}
export const ProductTypes = {
    "PHONE": "MobilePhones",
    "TABLET": "Tablets",
    "LAPTOP": "Laptops",
    "ACCESSORY": "Accessoires",
    "SUBSCRIPTION": "SUBSCRIPTION",
}
export const TagType = {
    EVERYONE: 'EVERYONE',
    DESIGNER: 'DESIGNER',
    DEVELOPER: 'DEVELOPER',
}
export const tempFeatureAllowedEmailDomains = ["newpath.com", "infobisnisdigital.com"]
export const nucliaAPI = 'https://europe-1.nuclia.cloud/api/v1'