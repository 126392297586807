import React from "react";
import TagsAndBudget from "./TagsAndBudget";
import { authRoles } from "app/auth/authRoles";

const tagsAndBudgetRoutes = [
    {
        path: "/tags",
        element: <TagsAndBudget />,
        auth: [authRoles.superAdmin, authRoles.siteAdmin, authRoles.smeAdmin],
        level: 1,
    },
];

export default tagsAndBudgetRoutes;
