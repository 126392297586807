import React, { lazy } from "react";
import Loadable from "app/components/Loadable/Loadable";
import { authRoles } from "app/auth/authRoles";

const AiAds = Loadable(lazy(() => import("./AiAds")));

const aiAdsRoutes = [
    {
        path: "/ai-ads",
        element: <AiAds />,
        auth: [authRoles.all],
        level: 1,
    },
];

export default aiAdsRoutes;
