import axios from 'axios.js';
import { get } from 'lodash';
import { setLoading } from "app/redux/actions/LoadingActions";
import { setMessage } from "app/redux/actions/MessageActions";

export const GET_SITES_LIST = "GET_SITES_LIST";
export const GET_SITE_BY_ID = "GET_SITE_BY_ID";
export const UPDATE_SITE_INFO = "UPDATE_SITE_INFO";
export const UPDATE_SITE_SUCCESS = "UPDATE_SITE_SUCCESS";
export const CREATE_SITE_SUCCESS = "CREATE_SITE_SUCCESS";
export const GET_SITE_PRODUCTS_LIST = "GET_SITE_PRODUCTS_LIST";
export const GET_SITE_PRODUCT_BY_ID = "GET_SITE_PRODUCT_BY_ID";
export const GET_SITE_WIDGETS_LIST = "GET_SITE_WIDGETS_LIST";
export const UPDATE_SITE_PRODUCT_SUCCESS = "UPDATE_SITE_PRODUCT_SUCCESS";
export const CREATE_SITE_PRODUCT_SUCCESS = "CREATE_SITE_PRODUCT_SUCCESS";
export const GET_EMAIL_TEMPLATE_LIST_BY_SITE_ID = "GET_EMAIL_TEMPLATE_LIST_BY_SITE_ID";

export const getSitesList = () => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.get('/site/list');
            dispatch({
                type: GET_SITES_LIST,
                payload: {
                    sitesList: get(response, "data.data", []),
                    status: null,
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export const getSiteById = (siteId) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.get(`/site/${siteId}`);

            dispatch({
                type: GET_SITE_BY_ID,
                payload: {
                    siteInEdit: get(response, "data.data", []),
                    status: null,
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export const updateSiteInfo = (data) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.put(`/site/update`, data);

            await dispatch(setMessage({ message: get(response, "data.message", ""), variant: "success" }));
            dispatch({
                type: UPDATE_SITE_SUCCESS,
                payload: {
                    status: get(response, "data.message", []),
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
            
        }
    }
}

export const createSiteInfo = (data) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.post(`/site/create`, data);

            await dispatch(setMessage({ message: get(response, "data.message", ""), variant: "success" }));
            dispatch({
                type: CREATE_SITE_SUCCESS,
                payload: {
                    status: get(response, "data.message", []),
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export const getProductsListBySiteId = (siteId) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.get(`product/site/${siteId}`);
            dispatch({
                type: GET_SITE_PRODUCTS_LIST,
                payload: {
                    siteProductsList: get(response, "data.data", []),
                    status: null,
                    productInEdit: {},
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export const getWidgetsBySiteId = (siteId) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.get(`product/site/${siteId}/widgets`);

            dispatch({
                type: GET_SITE_WIDGETS_LIST,
                payload: {
                    widgetList: get(response, "data.data", []),
                    status: null,
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export const getSiteProductById = (productId, siteId) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.get(`product/${productId}/site/${siteId}`);

            dispatch({
                type: GET_SITE_PRODUCT_BY_ID,
                payload: {
                    productInEdit: get(response, "data.data", []),
                    status: null,
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

// TODO
export const editProductBySiteId = (productid, siteId, data) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.put(`product/${productid}/site/${siteId}`, data);

            await dispatch(setMessage({ message: get(response, "data.message", ""), variant: "success" }));
            dispatch({
                type: UPDATE_SITE_PRODUCT_SUCCESS,
                payload: {
                    status: get(response, "data.message", []),
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

// TODO
export const createProductBySiteId = (siteId, data) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.post(`product/site/${siteId}`, data);

            await dispatch(setMessage({ message: get(response, "data.message", ""), variant: "success" }));
            dispatch({
                type: CREATE_SITE_PRODUCT_SUCCESS,
                payload: {
                    status: get(response, "data.message", []),
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export const getEmailTemplateListBySiteId = (siteId) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.get(`/site/email-templates/${siteId}`);

            dispatch({
                type: GET_EMAIL_TEMPLATE_LIST_BY_SITE_ID,
                payload: {
                    emailTemplateList: get(response, "data.data", []),
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export const sendEmailTemplateForPreview = (data) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.post(`/site/email-preview`, data);
            await dispatch(setMessage({ message: get(response, "data.message", ""), variant: "success" }));
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export const updateEmailTemplateListBySiteId = (siteId, data) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.put(`/site/email-templates/${siteId}`, data);
            await dispatch(setMessage({ message: get(response, "data.message", ""), variant: "success" }));
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}
