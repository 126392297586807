import {
    GET_WIDGET_LIST,
} from '../actions/WidgetActions'

const initialState = {
    widgets: [],
    railzConnectionId: '',
}

const TeamsReducer = function (state = initialState, action) {
    switch (action.type) {
    case GET_WIDGET_LIST: {
        return {
            ...state,
            ...action.payload,
        }
    }
    default: {
        return {
            ...state,
        }
    }
    }
}

export default TeamsReducer
