import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Divider } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '../button/Button';
import { useDispatch } from 'react-redux';
import { createTag, deleteTag, updateTag } from 'app/redux/actions/TagActions';
import GenericDialog from '../Dialog/GenericDialog';

export const TagsDialog = ({ opened, mode = "CREATE", handleTagClose, tag }) => {

    const [tagName, setTagName] = useState(tag ? tag.name : "")
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [error, setError] = useState("")
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        setError("")
        return handleTagClose
    }, [])

    const handleChange = (event) => {
        setTagName(event.target.value);
    };

    const handleCallBack = (mode) => {
        return (error, response) => {
            if (response && response.status === 200) {
                if (mode === 'CREATE') {
                    setIsSaving(false);
                    setTagName("");
                }
                else if (mode === "EDIT") {
                    setIsSaving(false);
                }
                else if (mode === "DELETE") {
                    setIsDeleting(false);
                }
                handleTagClose();
            } else if (error) {
                setIsDeleting(false);
                setIsSaving(false);
            }

        }
    }

    const createTags = (tag) => {
        setIsSaving(true)
        dispatch(createTag(tag, handleCallBack('CREATE')))
    }

    const editTags = (tag) => {
        setIsSaving(true)
        dispatch(updateTag(tag, handleCallBack('EDIT')))
    }

    const deleteTags = (tag) => {
        setIsDeleting(true)
        dispatch(deleteTag(tag.id, handleCallBack('DELETE')))
        setShowConfirmDelete(false)
    }

    const onClickSave = async () => {
        if (tagName.trim() === "") {
            setError(t('InvalidTagName'))
            return;
        }
        if (mode === "CREATE") {
            createTags({ name: tagName })
            return;
        }
        if (mode === "EDIT") {
            editTags({ id: tag.id, name: tagName })
        }
    }

    const handleTagDelete = async () => {
        setShowConfirmDelete(!showConfirmDelete)
    }

    return (
        <>
            <GenericDialog
                open={showConfirmDelete}
                handleClose={() => setShowConfirmDelete(false)}
                handleAccept={() => deleteTags(tag)}
                Message={t('AreYouSureYouWantToDeleteThis')}
            />
            <Dialog open={opened} onClose={handleTagClose} fullWidth>
                <BootstrapDialogTitle onClose={handleTagClose}>{mode === "CREATE" ? t('NewTag') : t('EditTag')}</BootstrapDialogTitle>
                <DialogContent>
                    <div style={{ margin: '1.5rem 0' }}>
                        <TextField
                            id="outlined-multiline-flexible"
                            label="Tag name"
                            onChange={handleChange}
                            value={tagName}
                            fullWidth
                            autoFocus
                            error={!!error}
                            helperText={error}
                        />
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Grid container columnSpacing={2} rowSpacing={3}>
                        <Grid item xs={6}>
                            {mode === "EDIT" && <LoadingButton loading={isDeleting} onClick={handleTagDelete} style={{ fontSize: '0.9rem' }}>{t('DeleteTag')}</LoadingButton>}
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                            <LoadingButton loading={isSaving} onClick={onClickSave} style={{ fontSize: '0.9rem' }} >{t('Save')}</LoadingButton>
                        </Grid>
                    </Grid>


                </DialogActions>
            </Dialog >
        </>


    );
}

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        fontSize: '1.2rem',
                    }}
                >
                    <CloseIcon sx={{
                        fontSize: '1.5rem',
                    }} />
                </IconButton>
            ) : null
            }
        </DialogTitle >
    );
};
