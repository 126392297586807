import { Box, Container, Grid } from "@mui/material";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import React from "react";
import useSettings from 'app/hooks/useSettings';

const MainContainer = styled(Container)(() => ({
    maxWidth: "1440px !important",
}));

const MainHeader = ({ style, children }) => {
    const { siteSettings: { theme: { header_bar_color: barColor } } } = useSettings();

    const MainWrapper = styled(Box)(({ theme }) => ({
        background: barColor,
        color: theme.palette.black.main,
        padding: '12px 0',
        [theme.breakpoints.up('md')]: {
            padding: '12px 0',
        },
    }));

    return (
        <MainWrapper style={style}>
            <MainContainer>
                <Grid container rowGap={3} justifyContent="space-between"
                    alignItems="center">
                    {children}
                </Grid>
            </MainContainer>
        </MainWrapper>
    );
};

const LeftContent = ({ children }) => {
    return <Grid item xs={12} md={6}>{children}</Grid>
};

const RightContent = ({ children }) => {
    return <Grid item xs={12} md={6} sx={{
        display: {
            md: 'flex',
        },
        justifyContent: {
            justifyContent: 'flex-end',
        },
    }} >{children}</Grid>
};

MainHeader.LeftContent = LeftContent;
MainHeader.RightContent = RightContent;

MainHeader.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    style: PropTypes.object,
};

export default MainHeader;
