import React from 'react'
import { Box, styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import CustomButton from "app/components/button/CustomButton";
import { Text } from 'app/components/Typography';

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}))

const JustifyBox = styled(FlexBox)(() => ({
    maxWidth: 320,
    flexDirection: 'column',
    justifyContent: 'center',
}))

const UnauthorizedText = styled(Text)(({ theme }) => ({
    color: '#3C55FF',
    fontSize: 60,
    fontWeight: 900,
    marginBottom: '32px',
    textShadow: '3px 3px #d0cfdf',
    [theme.breakpoints.down('sm')]: {
        fontSize: 30,
        width: '100%',
        margin: 'auto',
    },
}))

const UnauthorizedRoot = styled(FlexBox)(() => ({
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh !important',
}))

const NotFound = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()

    return (
        <UnauthorizedRoot>
            <JustifyBox>
                <UnauthorizedText>Unauthorized</UnauthorizedText>
                <CustomButton
                    onClick={() => navigate('/')}
                    label={t('BackToDashboard')}
                    id={"btn-back-to-dashboard"}
                />
            </JustifyBox>
        </UnauthorizedRoot>
    )
}

export default NotFound
