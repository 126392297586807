import React from 'react'
import { Navigate } from 'react-router-dom'
import useAuth from 'app/hooks/useAuth'

const Dashboard = () => {
    const { user = {} } = useAuth();
    const { landing_page: landingPage } = user || {};

    return <Navigate to={landingPage ?? "/dashboard"} />;
}

export default Dashboard
