import React, { lazy } from 'react';
import Loadable from 'app/components/Loadable/Loadable';
import { authRoles } from 'app/auth/authRoles';

const GetStarted = Loadable(lazy(() => import('./getStarted/GetStarted')));
const Settings = Loadable(lazy(() => import('./settings/Settings')));
const SiteList = Loadable(lazy(() => import('./site/SiteList')));
const SiteSettings = Loadable(lazy(() => import('./site/SiteSettings')));
const SmeList = Loadable(lazy(() => import('./sme/SmeList')));
const ProductList = Loadable(lazy(() => import('./products/ProductList')));
const ProductSettings = Loadable(lazy(() => import('./products/ProductSettings')));
const Profile = Loadable(lazy(() => import('./profile/Profile'))); // TODO: need to add again
const EmailTemplatesList = Loadable(lazy(() => import('./emailTemplates/EmailTemplatesList')));

const settingsRoutes = [
    {
        path: '/get-started',
        element: <GetStarted />,
        auth: [authRoles.superAdmin, authRoles.siteAdmin, authRoles.smeAdmin],
        level: 1,
    },
    {
        path: '/settings',
        element: <Settings />,
        auth: [authRoles.superAdmin, authRoles.siteAdmin, authRoles.smeAdmin],
        level: 1,
    },
    {
        path: '/site-list',
        element: <SiteList />,
        auth: [authRoles.superAdmin],
        level: 1,
    },
    {
        path: '/site-settings/edit/:siteid',
        element: <SiteSettings />,
        auth: [authRoles.superAdmin],
        level: 1,
    },
    {
        path: '/site-settings/new',
        element: <SiteSettings />,
        auth: [authRoles.superAdmin],
        level: 1,
    },
    {
        path: '/site/sme/:siteId',
        element: <SmeList />,
        auth: [authRoles.superAdmin],
        level: 1,
    },
    {
        path: '/site/products/:siteId',
        element: <ProductList />,
        auth: [authRoles.superAdmin],
        level: 1,
    },
    {
        path: '/product-list/:siteId',
        element: <ProductList />,
        auth: [authRoles.siteAdmin],
        level: 1,
    },
    {
        path: '/site/:siteid/products-settings/edit/:productid',
        element: <ProductSettings />,
        auth: [authRoles.superAdmin],
        level: 1,
    },
    {
        path: '/site/:siteid/products-settings/new',
        element: <ProductSettings />,
        auth: [authRoles.superAdmin],
        level: 1,
    },
    {
        path: '/profile',
        element: <Profile />,
        auth: [authRoles.all],
        level: 1,
    },
    {
        path: '/user/edit/:userId',
        element: <Profile />,
        auth: [authRoles.all],
        level: 1,
    },
    {
        path: '/sme-list/:siteId',
        element: <SmeList />,
        auth: [authRoles.superAdmin, authRoles.siteAdmin],
        level: 1,
    },
    {
        path: '/email-templates/:siteId',
        element: <EmailTemplatesList />,
        auth: [authRoles.superAdmin],
        level: 1,
    },
]

export default settingsRoutes;
