import { SET_MESSAGE } from '../actions/MessageActions'

const initialState = {
    anchorOrigin: {
        vertical: "top",
        horizontal: "center",
    },
    autoHideDuration: 3000,
    message: null,
    variant: null,
};

const MessageReducer = function (state = initialState, action) {
    switch (action.type) {
    case SET_MESSAGE: {
        return {
            ...state,
            ...action.payload,
        }
    }
    default: {
        return {
            ...state,
        }
    }
    }
};

export default MessageReducer;
