import {
    GET_SITES_LIST,
    GET_SITE_BY_ID,
    GET_SITE_PRODUCTS_LIST,
    GET_SITE_WIDGETS_LIST,
    UPDATE_SITE_SUCCESS,
    CREATE_SITE_SUCCESS,
    GET_SITE_PRODUCT_BY_ID,
    CREATE_SITE_PRODUCT_SUCCESS,
    UPDATE_SITE_PRODUCT_SUCCESS,
    GET_EMAIL_TEMPLATE_LIST_BY_SITE_ID,
} from '../actions/SitesActions'

const initialState = {
    sitesList: [],
    siteInEdit: {},
    status: null,
    siteProductsList: [],
    productInEdit: {},
    widgetList: [],
    emailTemplateList: [],
}

const SitesReducer = function (state = initialState, action) {
    switch (action.type) {
    case GET_SITES_LIST: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case GET_SITE_BY_ID: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case GET_SITE_PRODUCTS_LIST: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case GET_SITE_PRODUCT_BY_ID: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case GET_SITE_WIDGETS_LIST: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case UPDATE_SITE_SUCCESS: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case CREATE_SITE_SUCCESS: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case CREATE_SITE_PRODUCT_SUCCESS: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case UPDATE_SITE_PRODUCT_SUCCESS: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case GET_EMAIL_TEMPLATE_LIST_BY_SITE_ID: {
        return {
            ...state,
            ...action.payload,
        }
    }
    default: {
        return {
            ...state,
        }
    }
    }
}

export default SitesReducer
