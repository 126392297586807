import React from 'react'
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import CustomButton from "app/components/button/CustomButton";

const GenericDialog = ({ open, handleClose, handleAccept, title, Message, cancelButtonText, acceptButtonText }) => {
    const { t } = useTranslation();
    const loading = useSelector((state) => state.loading)

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
        >
            <DialogTitle id="dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="dialog-description">
                    {Message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {cancelButtonText || t('Cancel')}
                </Button>
                <CustomButton
                    onClick={handleAccept}
                    label={acceptButtonText || t('Yes')}
                    loading={loading}
                    variant={"text"}
                />
            </DialogActions>
        </Dialog>
    )
}

export default GenericDialog;