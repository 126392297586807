import React from 'react'
import App from './app/App'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { CartProvider } from 'use-shopping-cart'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
// import * as serviceWorker from './serviceWorker'
import { StyledEngineProvider } from '@mui/styled-engine'
import { CssBaseline } from '@mui/material'
import { defaultCurrency, allowedCountries } from 'app/utils/constant'

// TODO: Review and remove
const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

ReactDOM.createRoot(document.getElementById('root')).render(
    <StyledEngineProvider injectFirst>
        <BrowserRouter>
            <CssBaseline />
            <CartProvider
                mode="payment"
                cartMode="client-only"
                stripe={stripeKey}
                currency={defaultCurrency}
                allowedCountries={allowedCountries}
                billingAddressCollection={true}
            >
                <App />
            </CartProvider>
        </BrowserRouter>
    </StyledEngineProvider>,
)
