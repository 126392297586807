
import {
    Avatar,
    Box,
    Grid,
    Stack,
    styled,
} from '@mui/material'
import React, { useState } from 'react'
import Tag, { StatusTag } from 'app/components/Tag/Tag'
import UpdateTags from 'app/components/TeamsAndApprovals/UpdateTags'
import { get } from 'lodash'
import { Text } from 'app/components/Typography'
import { useNavigate } from 'react-router-dom'
import Button from 'app/components/button/Button'
import { useTranslation } from 'react-i18next';
import { getTagsSortedByIsDefault } from 'app/utils/utils'
import { isAdmin, formatText } from 'app/utils/utils';
import { formatPhoneNumberIntl } from 'react-phone-number-input'

const Card = styled(Box)(({ theme }) => ({
    padding: `${theme.spacing(3.5)} 0`,
    ":hover": {
        cursor: 'pointer',
        backgroundColor: theme.palette.offWhite.main,
    },
}))

const ProfilePicture = styled(Avatar)(() => ({
    width: 60,
    height: 60,
    background: 'rgba(36, 36, 36, 0.2)',
    color: '#717171',
    fontSize: 24,
    fontWeight: 500,
}))

const PendingStatusTag = styled(StatusTag)(() => ({
    marginLeft: 10,
}))

const Divider = styled('span')(() => ({
    paddingLeft: 10,
    paddingRight: 10,
}))

const ProductInfo = styled(Text)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        textAlign: 'right',
    },
    [theme.breakpoints.down('sm')]: {
        margin: '10px 0',
        textAlign: 'left',
    },
}))

const Tags = styled(Stack)(({ theme }) => ({
    display: 'block',
    textAlign: 'right',
    '& .MuiChip-filled': {
        height: '35px',
    },
    '.MuiChip-root': {
        margin: 5,
    },
    '.MuiButtonBase-root': {
        fontWeight: 600,
    },
    [theme.breakpoints.down('md')]: {
        justifyContent: 'left',
        marginTop: 10,
    },
    [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
        '.MuiChip-root': {
            margin: 5,
        },
        '.MuiButtonBase-root': {
            width: '100%',
            marginTop: 10,
        },
    },
}))

export const TeamMemberCard = ({ member }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id, firstName, surname, jobTitle, products, profilePic, role, tags, phone, email } = member;
    const [userTags, setUserTags] = useState(tags || []);
    const [enableUpdateTags, setEnableUpdateTags] = useState(false);

    const handleEnableUpdateTags = () => {
        setEnableUpdateTags(!enableUpdateTags);
    }

    const onClickMemberCard = () => {
        navigate(`/team-and-approvals/member/${id}/equipments`, { state: { member: { ...member, tags: userTags } } })
    }

    return (
        <Card data-testid="member-card" key={id}>
            <Grid container columnSpacing={3} >
                <Grid item md={'auto'} sm={1} xs={3} onClick={() => onClickMemberCard()}>
                    <ProfilePicture data-testid="profile-image" alt={`${firstName} ${surname}`} src={profilePic}>
                        {firstName.substring(0, 1)}
                    </ProfilePicture>
                </Grid>
                <Grid item md={4} sm={6} xs={9} onClick={() => onClickMemberCard()}>
                    <Text data-testid="member-name" fontWeight={600} marginBottom={0.5} >
                        {`${firstName} ${surname}`}
                        <StatusTag label={formatText(role, "_")} variant="outlined" sx={{ marginLeft: "8px", marginBottom: 0.5 }} />
                    </Text>
                    <Text data-testid="member-title">{jobTitle}</Text>
                    <Text data-testid="member-title" sx={{ fontSize: 12 }}>
                        {!!phone && (
                            <>
                                <a href={`tel:${phone}`} style={{ color: 'blue' }}>{formatPhoneNumberIntl(phone || '')}</a>
                                <Divider>|</Divider>
                            </>
                        )}
                        <a href={`mailto:${email}`} style={{ color: 'blue' }}>{email}</a>
                    </Text>
                </Grid>
                <Grid item md={3} sm={5} xs={12} onClick={() => onClickMemberCard()}>
                    <ProductInfo data-testid="products-held">
                        {get(products, 'held', 0)} {t('ProductsHeld')}
                        {get(products, 'pending', false) ? (
                            <PendingStatusTag data-testid="pending-approval-tag" label={`${get(products, 'pending', '')} ${t('Request')}`} color="warning" variant="outlined" />
                        ) : ''}
                    </ProductInfo>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                    <Tags direction="row" columnGap={1}>
                        {
                            userTags && getTagsSortedByIsDefault(userTags).map(tag => {
                                return <Tag
                                    label={tag?.name}
                                    key={tag?.id}
                                />
                            })
                        }
                        {isAdmin() && (
                            <Button
                                variant="outlined"
                                color="black"
                                onClick={() => handleEnableUpdateTags()}
                                sx={{ fontWeight: 500 }}
                                size="small"
                                disabled={enableUpdateTags}
                            >{t('UpdateTags')}</Button>
                        )}
                    </Tags>
                </Grid>
            </Grid>
            {enableUpdateTags && (
                <UpdateTags
                    open={enableUpdateTags}
                    handleEnableUpdateTags={handleEnableUpdateTags}
                    memberId={id}
                    userTags={userTags}
                    setUserTags={setUserTags}
                />
            )}
        </Card>
    )
}
