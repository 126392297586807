import axios from 'axios.js'
import { get } from 'lodash'
import { setLoading } from "app/redux/actions/LoadingActions";
import { setMessage } from "app/redux/actions/MessageActions";

export const GET_TEAM_MEMBERS = "GET_TEAM_MEMBERS";

export const getTeamMembers = (params) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.post('/user/team', params);
            dispatch({
                type: GET_TEAM_MEMBERS,
                payload: {
                    teamMembers: get(response, "data.data", []),
                    meta: get(response, "data.meta", {}),
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}
